import { CustomerReport } from '@models/products-and-orders/customer-reports';
import { createReducer, on } from '@ngrx/store';
import { sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './customer-reports.actions';

export interface State {
  isLoading: boolean;
  customerReportCreateLoading: boolean;
  customerReports: CustomerReport[];

  selectedCustomerReport: CustomerReport | null;
  selectedCustomerReportLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  customerReportCreateLoading: false,
  customerReports: [],

  selectedCustomerReport: null,
  selectedCustomerReportLoading: false,
};

export const сustomerReportsReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllComplete, (state, { customerReports }) => ({
    ...state,
    customerReports: sortAlphabetically(customerReports, sortByDate),
    isLoading: false,
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.addCustomerReport, state => ({ ...state, customerReportCreateLoading: true, isLoading: true })),
  on(fromActions.addCustomerReportComplete, (state, { customerReport }) => ({
    ...state,
    isLoading: false,
    customerReportCreateLoading: false,
    customerReports: sortAlphabetically([...state.customerReports, customerReport], sortByDate),
  })),
  on(fromActions.addCustomerReportError, state => ({ ...state, isLoading: false, customerReportCreateLoading: false })),

  on(fromActions.selectCustomerReport, state => ({ ...state, selectedCustomerReportLoading: true })),
  on(fromActions.selectCustomerReportComplete, (state, { customerReport }) => ({
    ...state,
    selectedCustomerReportLoading: false,
    selectedCustomerReport: customerReport,
  })),
  on(fromActions.selectCustomerReportError, state => ({ ...state, selectedCustomerReportLoading: false })),
);

export const sortByDate = (a: CustomerReport, b: CustomerReport): number => (a.reportPeriodFrom < b.reportPeriodFrom ? 1 : -1);
