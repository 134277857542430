import { CommentData, NewsArticleComment } from '@models/index';
import { createReducer, on } from '@ngrx/store';
import { findAndRemove, sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './comments.actions';
export interface State {
  comments: CommentData[];
  commentsLoading: boolean;

  selectedComment: NewsArticleComment | null;
  isSelectedCommentLoading: boolean;
}

export const initialState: State = {
  comments: [],
  commentsLoading: false,

  selectedComment: null,
  isSelectedCommentLoading: false,
};

export const commentsReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAllComments, state => ({ ...state, commentsLoading: true })),
  on(fromActions.getAllCommentsComplete, (state, { comments, resetSelectedComment }) => ({
    ...state,
    commentsLoading: false,
    comments: sortAlphabetically(comments, sortByDateTime),
    selectedComment: resetSelectedComment ? null : state.selectedComment,
  })),
  on(fromActions.getAllCommentsError, state => ({ ...state, commentsLoading: false, comments: [] })),

  on(fromActions.selectComment, state => ({ ...state, isSelectedCommentLoading: true })),
  on(fromActions.selectCommentComplete, (state, { comment }) => ({
    ...state,
    isSelectedCommentLoading: false,
    selectedComment: comment,
  })),
  on(fromActions.selectCommentError, state => ({
    ...state,
    isSelectedCommentLoading: false,
  })),

  on(fromActions.deleteSelectedComment, state => ({ ...state, commentsLoading: true })),
  on(fromActions.deleteSelectedCommentComplete, (state, { commentId }) => ({
    ...state,
    comments: findAndRemove<CommentData>(state.comments, (comment: CommentData) => comment.id === commentId),
    selectedComment: null,
    commentsLoading: false,
  })),
  on(fromActions.deleteSelectedCommentError, state => ({
    ...state,
    commentsLoading: false,
  })),
);

export const sortByDateTime = (a: CommentData, b: CommentData): number => (a.createdDateTime < b.createdDateTime ? 1 : -1);
