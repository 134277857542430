import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { actionTypesSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { isNotNullOrUndefined } from 'cui-components';
import { catchError, filter, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../app.store';
import * as fromActions from './action-types.actions';
import { ActionTypesService } from './action-types.service';
@Injectable()
export class ActionTypesEffects {
  onGetActionTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(() =>
        this.actionTypesService.getAll().pipe(
          map(actionTypes => fromActions.getAllComplete({ actionTypes })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onAddActionType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addActionType),
      switchMap(({ actionType }) =>
        this.actionTypesService.add(actionType).pipe(
          map(actionType => fromActions.addActionTypeComplete({ actionType })),
          catchError(err => of(fromActions.addActionTypeError({ err }))),
        ),
      ),
    ),
  );

  onAddActionTypeComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addActionTypeComplete),
        switchMap(({ actionType }) => this.router.navigate(['actions', 'types', actionType.code, 'details'])),
      ),
    { dispatch: false },
  );

  onUpdateActionType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateActionType),
      switchMap(({ code, actionType }) =>
        this.actionTypesService.update(code, actionType).pipe(
          map(actionType => fromActions.updateActionTypeComplete({ code, actionType })),
          catchError(err => of(fromActions.updateActionTypeError({ err }))),
        ),
      ),
    ),
  );

  onUpdateActionTypeComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.updateActionTypeComplete),
        tap(({ actionType }) => {
          this.location.go(`actions/types/${actionType.code}/details`);
        }),
      ),
    { dispatch: false },
  );

  onSelectActionType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectActionType),
      switchMap(({ code }) =>
        this.store.pipe(
          select(actionTypesSelectors.selectActionTypes),
          take(1),
          switchMap(actionTypes => {
            if (actionTypes.length) {
              const actionType = actionTypes.find(t => t.code === code)!;
              return of(fromActions.selectActionTypeComplete({ actionType }));
            } else {
              return this.actionTypesService.get(code).pipe(
                map(actionType => fromActions.selectActionTypeComplete({ actionType })),
                catchError(err => of(fromActions.selectActionTypeError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onDeleteActionType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedActionType),
      switchMap(() =>
        this.store.pipe(
          select(actionTypesSelectors.selectSelectedActionType),
          filter(isNotNullOrUndefined),
          take(1),
          switchMap(actionType =>
            this.actionTypesService.delete(actionType.code).pipe(
              map(() => fromActions.deleteSelectedActionTypeComplete()),
              catchError(err => of(fromActions.deleteSelectedActionTypeError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteActionTypeComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedActionTypeComplete),
      tap(() => this.router.navigate(['actions', 'types'])),
      map(() => fromActions.getAll()),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private actionTypesService: ActionTypesService,
    private location: Location,
  ) {}
}
