import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { downloadBlobUrlFileToBrowser } from '@highlander/common/helpers/file.helpers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { BlobStorageService, NotificationsService } from 'cui-components';
import { catchError, map, mergeMap, of, switchMap, take, tap } from 'rxjs';
import { AppConfigService } from 'src/app/infrastructure/app-config.service';
import { AppState } from '../app.store';
import * as fromActions from './documents.actions';
import * as selectors from './documents.selector';
import { DocumentsService } from './documents.service';

@Injectable()
export class DocumentsEffects {
  onGetDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllDocuments),
      switchMap(() =>
        this.documentsService.getDocuments().pipe(
          map(documents => fromActions.getAllDocumentsComplete({ documents })),
          catchError(err => of(fromActions.getAllDocumentsError({ err }))),
        ),
      ),
    ),
  );

  onCreateDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createDocument),
      switchMap(({ docDto, file }) =>
        this.documentsService.createDocument(docDto).pipe(
          tap(document => this.router.navigate(['marketing', 'documents', document.id, 'document-info'])),
          mergeMap(res => [
            fromActions.uploadFileToDocument({ url: res.URL, file, fromDocumentCreate: true, docId: res.id }),
            fromActions.createDocumentComplete(),
          ]),
          catchError(err => of(fromActions.createDocumentError({ err }))),
        ),
      ),
    ),
  );

  onDeleteDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedDocument),
      switchMap(() =>
        this.store.pipe(
          select(selectors.selectCurrentDocument),
          take(1),
          switchMap(doc =>
            this.documentsService.deleteDocument(doc!.id).pipe(
              map(() => fromActions.deleteSelectedDocumentComplete()),
              catchError(err => of(fromActions.deleteSelectedDocumentError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteDocumentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedDocumentComplete),
      tap(() => this.router.navigate(['marketing', 'documents'])),
      map(() => fromActions.getAllDocuments()),
    ),
  );

  onSelectDocumentListItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectDocument),
      switchMap(({ id }) =>
        this.documentsService.getDocument(id).pipe(
          tap(() => fromActions.goToDocumentActions({ docId: id })),
          map(doc => fromActions.selectDocumentComplete({ doc })),
          catchError(err => of(fromActions.selectDocumentError({ err }))),
        ),
      ),
    ),
  );

  onUploadFileToDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.uploadFileToDocument),
      switchMap(({ url, file, fromDocumentCreate, document, docId }) =>
        this.blobStorageService.uploadFile(url, file).pipe(
          map(() => fromActions.uploadFileToDocumentComplete({ fromDocumentCreate, document, docId })),
          catchError(err => of(fromActions.uploadFileToDocumentError({ err, docId }))),
        ),
      ),
    ),
  );

  onUploadFileToDocumentError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.uploadFileToDocumentError),
        // If docId is retrieved it means that this was createOperation and needs to be cleaned up
        switchMap(({ docId }) => {
          return docId ? this.documentsService.deleteDocument(docId!) : of(undefined);
        }),
      ),
    { dispatch: false },
  );

  onGetFileUploadUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getFileUploadURL),
      switchMap(({ id, file, fileName }) =>
        this.documentsService.uploadNewFileToExistingDoc(id, fileName).pipe(
          map(({ URL, document }) => fromActions.uploadFileToDocument({ url: URL, file, fromDocumentCreate: false, document, docId: id })),
          catchError(err => of(fromActions.getFileUploadURLError({ err }))),
        ),
      ),
    ),
  );

  onUpdateDocumentCommonData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateDocumentCommonData),
      switchMap(({ docId, commonDataType, data }) =>
        this.documentsService.updateCommonData(docId, commonDataType, data).pipe(
          map(doc => fromActions.updateDocumentCommonDataComplete({ doc })),
          catchError(err => of(fromActions.updateDocumentCommonDataError({ err }))),
        ),
      ),
    ),
  );

  onUpdateDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateDocument),
      switchMap(({ doc }) =>
        this.documentsService.updateDocument(doc).pipe(
          map(doc => fromActions.updateDocumentComplete({ doc })),
          catchError(err => of(fromActions.updateDocumentError({ err }))),
        ),
      ),
    ),
  );

  onPublishOrUnpublishDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.publishOrUnpublishDocument),
      switchMap(({ id, publishDate, wantToPublish }) =>
        this.documentsService.documentPublishment(id, publishDate, wantToPublish).pipe(
          map(doc => fromActions.publishOrUnpublishDocumentComplete({ doc })),
          catchError(err => of(fromActions.publishOrUnpublishDocumentError({ err }))),
        ),
      ),
    ),
  );

  onDocumentsComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.createDocumentComplete,
        fromActions.updateDocumentComplete,
        fromActions.publishOrUnpublishDocumentComplete,
        fromActions.updateDocumentCommonDataComplete,
      ),
      map(() => fromActions.getAllDocuments()),
    ),
  );

  onUploadFileToDocumentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.uploadFileToDocumentComplete),
      map(({ docId }) => fromActions.goToDocumentInformation({ docId })),
    ),
  );

  onCopyActiveDocExternalLink$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.copyActiveDocExternalLink),
        switchMap(() =>
          this.store.pipe(
            select(selectors.selectCurrentDocument),
            take(1),
            map(doc => {
              this.clipboard.copy(`${this.appConfig.config['FRONTEND_PORTAL_URL']}/documents/${doc?.userFriendlyName}/download`);
              this.notifications.showNotification('Copied successfully!');
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  onDownloadSelectedDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadSelectedDocument),
      switchMap(() =>
        this.store.pipe(
          select(selectors.selectCurrentDocumentId),
          take(1),
          switchMap(id =>
            this.documentsService.download(id!).pipe(
              map(res => fromActions.downloadSelectedDocumentComplete({ url: res.URL })),
              catchError(() => of(fromActions.downloadSelectedDocumentError())),
            ),
          ),
        ),
      ),
    ),
  );

  onDownloadSelectedDocumentComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.downloadSelectedDocumentComplete),
        tap(({ url }) => {
          downloadBlobUrlFileToBrowser(url);
        }),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToListPage),
      tap(() => this.router.navigate(['marketing', 'documents'])),
      map(() => fromActions.resetState({ selectedDocument: null })),
    ),
  );

  onGoToDocumentActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToDocumentActions),
        tap(({ docId }) => this.router.navigate(['marketing', 'documents', docId])),
      ),
    { dispatch: false },
  );

  onGoToSelectedDocumentActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToSelectedDocumentActions),
        switchMap(() =>
          this.store.pipe(
            select(selectors.selectCurrentDocumentId),
            take(1),
            tap(id => this.router.navigate(['marketing', 'documents', id])),
          ),
        ),
      ),
    { dispatch: false },
  );

  onGoToDocumentInformation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToDocumentInformation),
        tap(({ docId }) => this.router.navigate(['marketing', 'documents', docId, 'document-info'])),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly documentsService: DocumentsService,
    private readonly blobStorageService: BlobStorageService,
    private readonly clipboard: Clipboard,
    private readonly appConfig: AppConfigService,
    private readonly notifications: NotificationsService,
  ) {}
}
