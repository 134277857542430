import { HttpErrorResponse } from '@angular/common/http';
import { GetPartnerNameByMpnIdRequest, GetPartnerNameByMpnIdResponse } from '@models/csp/csp-util/csp-util.model';
import { createAction, props } from '@ngrx/store';
import { State } from './csp-util.reducer';

const NAMESPACE = '[Csp Util]';

export const getPartnerNameByMpnId = createAction(`${NAMESPACE} GetPartnerNameByMpnId`, props<{ req: GetPartnerNameByMpnIdRequest }>());
export const getPartnerNameByMpnIdComplete = createAction(
  `${NAMESPACE} GetPartnerNameByMpnIdComplete`,
  props<{ res: GetPartnerNameByMpnIdResponse }>(),
);
export const getPartnerNameByMpnIdError = createAction(`${NAMESPACE} GetPartnerNameByMpnIdError`, props<{ err: HttpErrorResponse }>());

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
