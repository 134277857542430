import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateCustomerReportRequest, CustomerReport, CustomerReportParams } from '@models/products-and-orders/customer-reports';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CustomerReportsService {
  private readonly url = `${environment.bffUrl}/customer-reports`;
  constructor(private httpClient: HttpClient) {}

  getAll(params?: CustomerReportParams): Observable<CustomerReport[]> {
    return this.httpClient.get<CustomerReport[]>(this.url, { params });
  }

  get(id: string): Observable<CustomerReport> {
    return this.httpClient.get<CustomerReport>(`${this.url}/${id}`);
  }

  add(req: CreateCustomerReportRequest): Observable<CustomerReport> {
    return this.httpClient.post<CustomerReport>(this.url, req);
  }
}
