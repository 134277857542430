import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RegionListItem } from '@models/index';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class RegionsService {
  private readonly url = `${environment.bffUrl}/regions`;
  constructor(private http: HttpClient) {}

  getAll(): Observable<RegionListItem[]> {
    return this.http.get<RegionListItem[]>(this.url);
  }
  getOne(id: string): Observable<RegionListItem> {
    return this.http.get<RegionListItem>(`${this.url}/${id}`);
  }
}
