import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionType } from '@models/index';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ActionTypesService {
  private readonly url = `${environment.bffUrl}/action-types`;
  constructor(private httpClient: HttpClient) {}

  getAll(): Observable<ActionType[]> {
    return this.httpClient.get<ActionType[]>(this.url);
  }

  get(code: string): Observable<ActionType> {
    return this.httpClient.get<ActionType>(`${this.url}/${code}`);
  }

  add(actionType: ActionType): Observable<ActionType> {
    return this.httpClient.post<ActionType>(this.url, actionType);
  }

  update(code: string, actionType: ActionType): Observable<ActionType> {
    return this.httpClient.put<ActionType>(`${this.url}/${code}`, actionType);
  }

  delete(code: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${code}`);
  }
}
