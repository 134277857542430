import { HttpErrorResponse } from '@angular/common/http';
import { CreateCustomerReportRequest, CustomerReport } from '@models/products-and-orders/customer-reports';
import { createAction, props } from '@ngrx/store';
import { State } from './customer-reports.reducer';

const NAMESPACE = '[CustomerReports]';

export const getAll = createAction(`${NAMESPACE} GetAll`);
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ customerReports: CustomerReport[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const addCustomerReport = createAction(`${NAMESPACE} AddCustomerReport`, props<{ customerReport: CreateCustomerReportRequest }>());
export const addCustomerReportComplete = createAction(
  `${NAMESPACE} AddCustomerReportComplete`,
  props<{ customerReport: CustomerReport }>(),
);
export const addCustomerReportError = createAction(`${NAMESPACE} AddCustomerReportError`, props<{ err: HttpErrorResponse }>());

export const selectCustomerReport = createAction(`${NAMESPACE} SelectCustomerReport`, props<{ id: string }>());
export const selectCustomerReportComplete = createAction(
  `${NAMESPACE} SelectCustomerReportComplete`,
  props<{ customerReport: CustomerReport }>(),
);
export const selectCustomerReportError = createAction(`${NAMESPACE} SelectCustomerReportError`, props<{ err: HttpErrorResponse }>());

export const goToCustomerReportActions = createAction(`${NAMESPACE} GoToCustomerReportActions`, props<{ id: string }>());
export const goToListPage = createAction(`${NAMESPACE} GoToListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
