import { ActionType } from '@models/index';
import { createReducer, on } from '@ngrx/store';
import { findAndReplace, predicateForSorting, sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './action-types.actions';

export interface State {
  isLoading: boolean;
  actionTypeCreateLoading: boolean;
  actionTypes: ActionType[];

  selectedActionType: ActionType | null;
  selectedActionTypeLoading: boolean;
}

export const initialState: State = {
  actionTypeCreateLoading: false,

  isLoading: false,
  actionTypes: [],

  selectedActionType: null,
  selectedActionTypeLoading: false,
};

export const actionTypesReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllComplete, (state, { actionTypes }) => ({
    ...state,
    actionTypes: sortAlphabetically(actionTypes, predicateForSorting('code')),
    isLoading: false,
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.addActionType, state => ({ ...state, actionTypeCreateLoading: true, isLoading: true })),
  on(fromActions.addActionTypeComplete, (state, { actionType }) => ({
    ...state,
    isLoading: false,
    actionTypeCreateLoading: false,
    actionTypes: sortAlphabetically([...state.actionTypes, actionType], predicateForSorting('code')),
  })),
  on(fromActions.addActionTypeError, state => ({ ...state, isLoading: false, actionTypeCreateLoading: false })),

  on(fromActions.updateActionType, state => ({ ...state, selectedActionTypeLoading: true, isLoading: true })),
  on(fromActions.updateActionTypeComplete, (state, { code, actionType }) => ({
    ...state,
    isLoading: false,
    selectedActionTypeLoading: false,
    actionTypes: sortAlphabetically(
      findAndReplace<ActionType>(state.actionTypes, actionType, item => item.code === code), // This needs to be fixed, because if code is changes we are screwed
      predicateForSorting('code'),
    ),
    selectedActionType: actionType,
  })),
  on(fromActions.updateActionTypeError, state => ({
    ...state,
    selectedActionType: { ...state.selectedActionType! },
    isLoading: false,
    selectedActionTypeLoading: false,
  })),

  on(fromActions.selectActionType, state => ({ ...state, selectedActionTypeLoading: true })),
  on(fromActions.selectActionTypeComplete, (state, { actionType }) => ({
    ...state,
    selectedActionTypeLoading: false,
    selectedActionType: actionType,
  })),
  on(fromActions.selectActionTypeError, state => ({ ...state, selectedActionTypeLoading: false })),

  on(fromActions.deleteSelectedActionType, state => ({ ...state, selectedActionTypeLoading: true })),
  on(fromActions.deleteSelectedActionTypeComplete, state => ({
    ...state,
    selectedActionTypeLoading: false,
    selectedActionType: null,
  })),
  on(fromActions.deleteSelectedActionTypeError, state => ({
    ...state,
    selectedActionTypeLoading: false,
  })),
);
