import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.cspTenants);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectIsTenantCreateLoading = createSelector(selectModuleState, state => state.tenantCreateLoading);
export const selectTenants = createSelector(selectModuleState, state => state.tenants);

export const selectSelectedTenant = createSelector(selectModuleState, state => state.selectedTenant);
export const selectSelectedTenantId = createSelector(selectModuleState, state => state.selectedTenant && state.selectedTenant.id);
export const selectSelectedTenantLoading = createSelector(selectModuleState, state => state.selectedTenantLoading);
