import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import {
  actionTypesActions,
  agreementsActions,
  announcementsActions,
  assessmentsActions,
  branchesActions,
  commentsActions,
  commonDataActions,
  cspTenantsActions,
  cspUtilActions,
  customerReportsActions,
  documentsActions,
  externalTemplatesActions,
  filesActions,
  identityActions,
  identitySelectors,
  logsActions,
  newsArticlesActions,
  newsRegionsActions,
  partnersActions,
  permissionsActions,
  productsActions,
  receiverGroupsActions,
  regionsActions,
  roleGroupsActions,
  rolesActions,
  templateGroupsActions,
  templatesActions,
  topicsActions,
  usersActions,
} from '@appState';
import { UserModel } from '@models/index';

import { Store, select } from '@ngrx/store';
import { DialogService, NavClickEvent, NavExpandNode, SubscribingBase } from 'cui-components';
import { Observable, combineLatest, filter, map, mergeMap } from 'rxjs';
import { MpnIdCheckDialogComponent } from 'src/app/app-pages/csp/mpn-id-check/mpn-id-check-dialog.component';
import { AppState } from 'src/app/app-state/app.store';
import { AppConfigService } from 'src/app/infrastructure';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ocf-authenticated-template-component',
  templateUrl: './authenticated-template.component.html',
  styleUrls: ['./authenticated-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthenticatedTemplateComponent extends SubscribingBase implements OnInit {
  isAuthenticated$: Observable<boolean>;
  isSystemAdmin$: Observable<boolean>;
  userModel$: Observable<UserModel>;
  hasUserMenuAccess$: Observable<boolean>;
  hasAgreementMenuAccess$: Observable<boolean>;
  hasCommonDataMenuAccess$: Observable<boolean>;
  hasAuthorizationMenuAccess$: Observable<boolean>;
  hasActionsMenuAccess$: Observable<boolean>;
  hasPartnersMenuAccess$: Observable<boolean>;
  hasMarketingMenuAccess$: Observable<boolean>;
  hasFileManagementMenuAccess$: Observable<boolean>;
  hasProductsAndOrdersMenuAccess$: Observable<boolean>;
  hasNotificationsMenuAccess$: Observable<boolean>;
  hasCspMenuAccess$: Observable<boolean>;

  navExpandAuthorization: NavExpandNode[] = [
    {
      id: '1',
      parentId: null,
      name: 'Authorization',
      icon: 'lock',
      children: [],
    },
  ];

  navExpandActions: NavExpandNode[] = [
    {
      id: '1',
      parentId: null,
      name: 'Actions',
      icon: 'touch_app',
      children: [],
    },
  ];

  navExpandMarketing: NavExpandNode[] = [
    {
      id: '5',
      parentId: null,
      name: 'Marketing',
      icon: 'bar_chart',
      children: [],
    },
  ];

  navExpandFileManagement: NavExpandNode[] = [
    {
      id: '9',
      parentId: null,
      name: 'File Management',
      icon: 'file_copy',
      children: [],
    },
  ];

  navExpandProductsAndOrders: NavExpandNode[] = [
    {
      id: '12',
      parentId: null,
      name: 'Products & Orders',
      icon: 'list_alt',
      children: [],
    },
  ];

  navExpandCsp: NavExpandNode[] = [
    {
      id: '22',
      parentId: null,
      name: 'CSP',
      icon: 'cloud',
      children: [],
    },
  ];
  navExpandNotifications: NavExpandNode[] = [
    {
      id: '14',
      parentId: null,
      name: 'Notifications',
      icon: 'circle_notifications',
      children: [],
    },
  ];

  topBgColor: string;
  pageTitle: string;

  constructor(
    private readonly store: Store<AppState>,
    private readonly appConfig: AppConfigService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly dialogService: DialogService,
  ) {
    super();
    this.topBgColor = appConfig.config['FRONTEND_TOP_MENU_BG_COLOR'] ?? '#ff2f2f80';
    this.pageTitle = this.findPageTitle(route.snapshot);
    this.isAuthenticated$ = this.store.pipe(select(identitySelectors.selectIsAuthenticated));
    this.isSystemAdmin$ = this.store.pipe(select(identitySelectors.selectIsSystemAdmin));
    this.userModel$ = this.store.pipe(select(identitySelectors.selectUser));

    this.hasUserMenuAccess$ = this.store.pipe(
      select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.Users.MenuAccess'])),
    );

    this.hasAgreementMenuAccess$ = this.store.pipe(
      select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.Agreements.MenuAccess'])),
    );

    this.hasCommonDataMenuAccess$ = this.store.pipe(
      select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.CommonData.MenuAccess'])),
    );

    this.hasPartnersMenuAccess$ = this.store.pipe(
      select(
        identitySelectors.selectHasPermissionToAccessPage({
          menuAccess: ['Portal.Admin.Partners.MenuAccess'],
        }),
      ),
    );

    this.hasAuthorizationMenuAccess$ = combineLatest({
      hasAuthorizationMenuAccess: this.store.pipe(
        select(
          identitySelectors.selectHasPermissionToAccessPage({
            menuAccess: ['Portal.Admin.Authorization.MenuAccess'],
          }),
        ),
      ),
      hasPermissionsMenuAccess: this.store.pipe(
        select(
          identitySelectors.selectHasPermissionToAccessPage({
            menuAccess: ['Portal.Admin.Authorization.Permissions.MenuAccess'],
          }),
        ),
      ),
      hasRolesMenuAccess: this.store.pipe(
        select(
          identitySelectors.selectHasPermissionToAccessPage({
            menuAccess: ['Portal.Admin.Authorization.Roles.MenuAccess'],
          }),
        ),
      ),
      hasRoleGroupsMenuAccess: this.store.pipe(
        select(
          identitySelectors.selectHasPermissionToAccessPage({
            menuAccess: ['Portal.Admin.Authorization.RoleGroups.MenuAccess'],
          }),
        ),
      ),
    }).pipe(
      select(({ hasAuthorizationMenuAccess, hasPermissionsMenuAccess, hasRolesMenuAccess, hasRoleGroupsMenuAccess }) => {
        if (hasRoleGroupsMenuAccess) {
          this.navExpandAuthorization[0].children = [
            { id: '2', parentId: '1', name: 'Role Groups', icon: 'groups_3', url: '/role-groups' },
          ];
        }

        if (hasRolesMenuAccess) {
          this.navExpandAuthorization[0].children = [
            ...this.navExpandAuthorization[0].children!,
            { id: '3', parentId: '1', name: 'Roles', icon: 'workspaces', url: '/roles' },
          ];
        }

        if (hasPermissionsMenuAccess) {
          this.navExpandAuthorization[0].children = [
            ...this.navExpandAuthorization[0].children!,
            { id: '4', parentId: '1', name: 'Permissions', url: '/permissions', icon: 'key' },
          ];
        }
        return hasAuthorizationMenuAccess;
      }),
    );

    this.hasActionsMenuAccess$ = combineLatest({
      hasActionsMenuAccess: this.store.pipe(
        select(
          identitySelectors.selectHasPermissionToAccessPage({
            menuAccess: ['Portal.Admin.Actions.MenuAccess'],
          }),
        ),
      ),
      hasActionLogsMenuAccess: this.store.pipe(
        select(
          identitySelectors.selectHasPermissionToAccessPage({
            menuAccess: ['Portal.Admin.Actions.Logs.MenuAccess'],
          }),
        ),
      ),
      hasActionTypesMenuAccess: this.store.pipe(
        select(
          identitySelectors.selectHasPermissionToAccessPage({
            menuAccess: ['Portal.Admin.Actions.Types.MenuAccess'],
          }),
        ),
      ),
    }).pipe(
      select(({ hasActionsMenuAccess, hasActionLogsMenuAccess, hasActionTypesMenuAccess }) => {
        if (hasActionLogsMenuAccess) {
          this.navExpandActions[0].children = [{ id: '2', parentId: '1', name: 'Logs', icon: 'troubleshoot', url: '/actions/logs' }];
        }

        if (hasActionTypesMenuAccess) {
          this.navExpandActions[0].children = [
            ...this.navExpandActions[0].children!,
            { id: '3', parentId: '1', name: 'Types', icon: 'checklist_rtl', url: '/actions/types' },
          ];
        }

        return hasActionsMenuAccess;
      }),
    );

    this.hasMarketingMenuAccess$ = combineLatest({
      hasMarketingMenuAccess: this.store.pipe(
        select(
          identitySelectors.selectHasPermissionToAccessPage({
            menuAccess: ['Portal.Admin.Marketing.MenuAccess'],
          }),
        ),
      ),
      hasAnnouncementsMenuAccess: this.store.pipe(
        select(
          identitySelectors.selectHasPermissionToAccessPage({
            menuAccess: ['Portal.Admin.Marketing.Announcements.MenuAccess'],
          }),
        ),
      ),
      hasRegionsMenuAccess: this.store.pipe(
        select(
          identitySelectors.selectHasPermissionToAccessPage({
            menuAccess: ['Portal.Admin.Marketing.Regions.MenuAccess'],
          }),
        ),
      ),
      hasNewsArticlesAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.Marketing.NewsArticles.MenuAccess'])),
      ),
      hasDocumentsAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.Marketing.Documents.MenuAccess'])),
      ),
      hasTopicsAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.Marketing.Topics.MenuAccess'])),
      ),
      hasCommentsAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.Marketing.Comments.MenuAccess'])),
      ),
    }).pipe(
      select(
        ({
          hasMarketingMenuAccess,
          hasAnnouncementsMenuAccess,
          hasDocumentsAccess,
          hasTopicsAccess,
          hasRegionsMenuAccess,
          hasNewsArticlesAccess,
          hasCommentsAccess,
        }) => {
          if (hasRegionsMenuAccess) {
            this.navExpandMarketing[0].children = [
              {
                name: 'Regions',
                parentId: '5',
                isFirstWithGroupName: true,
                groupName: 'Regional view',
                id: '6',
                url: '/marketing/regions',
                icon: 'announcement',
              },
            ];
          }

          if (hasAnnouncementsMenuAccess) {
            this.navExpandMarketing[0].children = [
              ...this.navExpandMarketing[0].children!,
              {
                name: 'Announcements',
                parentId: '5',
                isFirstWithGroupName: true,
                groupName: 'Information Hub',
                id: '7',
                url: '/marketing/announcements',
                icon: 'announcement',
              },
            ];
          }

          if (hasNewsArticlesAccess) {
            this.navExpandMarketing[0].children = [
              ...this.navExpandMarketing[0].children!,
              {
                name: 'News',
                parentId: '5',
                id: '18',
                groupName: 'Information Hub',
                url: '/marketing/news-articles',
                icon: 'feed',
              },
            ];
          }

          if (hasCommentsAccess) {
            this.navExpandMarketing[0].children = [
              ...this.navExpandMarketing[0].children!,
              {
                name: 'Comments',
                parentId: '5',
                id: '21',
                groupName: 'Information Hub',
                url: '/marketing/news-articles-comments',
                icon: 'forum',
              },
            ];
          }

          if (hasDocumentsAccess) {
            this.navExpandMarketing[0].children = [
              ...this.navExpandMarketing[0].children!,
              {
                name: 'Documents',
                parentId: '5',
                id: '8',
                isFirstWithGroupName: !hasAnnouncementsMenuAccess,
                groupName: 'Information Hub',
                url: '/marketing/documents',
                icon: 'description',
              },
            ];
          }

          if (hasTopicsAccess) {
            this.navExpandMarketing[0].children = [
              ...this.navExpandMarketing[0].children!,
              {
                name: 'Topics',
                parentId: '5',
                id: '9',
                isFirstWithGroupName: true,
                groupName: 'Configuration',
                url: '/marketing/topics',
                icon: 'list',
              },
            ];
          }
          return hasMarketingMenuAccess;
        },
      ),
    );

    this.hasFileManagementMenuAccess$ = combineLatest({
      hasFileManagementMenuAccess: this.store.pipe(
        select(
          identitySelectors.selectHasPermissionToAccessPage({
            menuAccess: ['Portal.Admin.FileManagement.MenuAccess'],
          }),
        ),
      ),
      hasBranchesAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.FileManagement.Branches.MenuAccess'])),
      ),
      hasFilesAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.FileManagement.Files.MenuAccess'])),
      ),
    }).pipe(
      select(({ hasFileManagementMenuAccess, hasBranchesAccess, hasFilesAccess }) => {
        if (hasBranchesAccess || hasFilesAccess) {
          this.navExpandFileManagement[0].children = [
            ...this.navExpandFileManagement[0].children!,
            {
              name: 'Branches',
              parentId: '9',
              id: '10',
              url: '/file-management/branches',
              icon: 'settings',
            },
          ];
        }
        if (hasFilesAccess) {
          this.navExpandFileManagement[0].children = [
            ...this.navExpandFileManagement[0].children!,
            {
              name: 'Files',
              parentId: '9',
              id: '11',
              url: '/file-management/files',
              icon: 'description',
            },
          ];
        }
        return hasFileManagementMenuAccess;
      }),
    );
    this.hasProductsAndOrdersMenuAccess$ = combineLatest({
      hasProductsAndOrdersMenuAccess: this.store.pipe(
        select(
          identitySelectors.selectHasPermissionToAccessPage({
            menuAccess: ['Portal.Admin.ProductsAndOrders.MenuAccess'],
          }),
        ),
      ),
      hasProductsAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.ProductsAndOrders.Products.MenuAccess'])),
      ),
      hasAssessmentsAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.ProductsAndOrders.Assessments.MenuAccess'])),
      ),
      hasCustomerReportsAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.ProductsAndOrders.CustomerReports.MenuAccess'])),
      ),
    }).pipe(
      select(({ hasProductsAndOrdersMenuAccess, hasProductsAccess, hasAssessmentsAccess, hasCustomerReportsAccess }) => {
        if (hasProductsAccess) {
          this.navExpandProductsAndOrders[0].children = [
            ...this.navExpandProductsAndOrders[0].children!,
            {
              name: 'Products',
              parentId: '12',
              id: '13',
              url: '/products',
              icon: 'shopping_bag',
            },
          ];
        }

        if (hasAssessmentsAccess) {
          this.navExpandProductsAndOrders[0].children = [
            ...this.navExpandProductsAndOrders[0].children!,
            {
              name: 'Assessments',
              parentId: '12',
              id: '20',
              url: '/assessments',
              icon: 'assessment',
            },
          ];
        }
        if (hasCustomerReportsAccess) {
          this.navExpandProductsAndOrders[0].children = [
            ...this.navExpandProductsAndOrders[0].children!,
            {
              name: 'Customer Reports',
              parentId: '12',
              id: '24',
              url: '/customer-reports',
              icon: 'description',
            },
          ];
        }
        return hasProductsAndOrdersMenuAccess;
      }),
    );

    this.hasNotificationsMenuAccess$ = combineLatest({
      hasNotificationsMenuAccess: this.store.pipe(
        select(
          identitySelectors.selectHasPermissionToAccessPage({
            menuAccess: ['Portal.Admin.Notifications.MenuAccess'],
          }),
        ),
      ),
      hasReceiverGroupsAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.Notifications.ReceiverGroups.MenuAccess'])),
      ),
      hasExternalTemplateAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.Notifications.ExternalTemplates.MenuAccess'])),
      ),
      hasTemplateGroupsAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.Notifications.TemplateGroups.MenuAccess'])),
      ),
      hasTemplateAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.Notifications.Templates.MenuAccess'])),
      ),
      hasNotificationLogAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.Notifications.Logs.MenuAccess'])),
      ),
    }).pipe(
      select(
        ({
          hasNotificationsMenuAccess,
          hasReceiverGroupsAccess,
          hasExternalTemplateAccess,
          hasTemplateGroupsAccess,
          hasTemplateAccess,
          hasNotificationLogAccess,
        }) => {
          if (hasTemplateGroupsAccess) {
            this.navExpandNotifications[0].children = [
              ...this.navExpandNotifications[0].children!,
              {
                name: 'Template Groups',
                parentId: '14',
                id: '15',
                url: '/notifications/template-groups',
                icon: 'mark_as_unread',
              },
            ];
          }
          if (hasTemplateAccess) {
            this.navExpandNotifications[0].children = [
              ...this.navExpandNotifications[0].children!,
              {
                name: 'Templates',
                parentId: '14',
                id: '16',
                url: '/notifications/templates',
                icon: 'drafts',
              },
            ];
          }
          if (hasExternalTemplateAccess) {
            this.navExpandNotifications[0].children = [
              ...this.navExpandNotifications[0].children!,
              {
                name: 'External Templates',
                parentId: '14',
                id: '17',
                url: '/notifications/external-templates',
                icon: 'attach_email',
              },
            ];
            if (hasReceiverGroupsAccess) {
              this.navExpandNotifications[0].children = [
                ...this.navExpandNotifications[0].children!,
                {
                  name: 'Receiver Groups',
                  parentId: '14',
                  id: '18',
                  url: '/notifications/groups',
                  icon: 'menu',
                },
              ];
            }

            if (hasNotificationLogAccess) {
              this.navExpandNotifications[0].children = [
                ...this.navExpandNotifications[0].children!,
                {
                  name: 'Log',
                  parentId: '14',
                  id: '19',
                  url: '/notifications/logs',
                  icon: 'troubleshoot',
                },
              ];
            }
          }

          return hasNotificationsMenuAccess;
        },
      ),
    );

    this.hasCspMenuAccess$ = combineLatest({
      hasCspMenuAccess: this.store.pipe(
        select(
          identitySelectors.selectHasPermissionToAccessPage({
            menuAccess: ['Portal.Admin.Csp.MenuAccess'],
          }),
        ),
      ),
      hasTenantsAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.Csp.Tenants.MenuAccess'])),
      ),
      hasMpnIdCheckAccess: this.store.pipe(
        select(identitySelectors.selectHasPermissionForMenuAccess(['Portal.Admin.Csp.MpnIdCheck.MenuAccess'])),
      ),
    }).pipe(
      select(({ hasCspMenuAccess, hasTenantsAccess, hasMpnIdCheckAccess }) => {
        if (hasTenantsAccess) {
          this.navExpandCsp[0].children = [
            ...this.navExpandCsp[0].children!,
            {
              name: 'Tenants',
              parentId: '22',
              id: '23',
              url: '/csp/tenants',
              icon: 'menu',
            },
          ];
        }
        if (hasMpnIdCheckAccess) {
          this.navExpandCsp[0].children = [
            ...this.navExpandCsp[0].children!,
            {
              name: 'MPN Id check',
              parentId: '22',
              id: '26',
              icon: 'menu',
              customAction: 'MpnIdCheck',
            },
          ];
        }
        return hasCspMenuAccess;
      }),
    );
  }

  ngOnInit(): void {
    this.initRefreshTokenPolling();
    this.subscribe(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        mergeMap(route => {
          this.pageTitle = this.findPageTitle(route.snapshot);
          return this.pageTitle;
        }),
      ),
      () => {},
    );
  }

  onLinkClicked({ url, customAction }: NavClickEvent): void {
    switch (url) {
      case '/marketing/documents':
        this.store.dispatch(documentsActions.resetState({ selectedDocument: null }));
        break;
      case '/users':
        this.store.dispatch(usersActions.resetState({ selectedUserId: null, selectedUserRoles: [] }));
        break;
      case '/partners':
        this.store.dispatch(
          partnersActions.resetState({
            selectedPartner: null,
            partnerRoles: [],
            partnerBusinessData: null,
            partnerAdminUsers: [],
            indirectProviders: [],
            selectedIndirectProvider: null,
          }),
        );
        break;
      case '/permissions':
        this.store.dispatch(permissionsActions.resetState({ selectedPermission: null, roles: [], attributes: [] }));
        break;
      case '/roles':
        this.store.dispatch(
          rolesActions.resetState({ activeRole: null, permissions: [], attributes: [], defaults: [], activeAttribute: null }),
        );
        break;
      case '/role-groups':
        this.store.dispatch(roleGroupsActions.resetState({ selectedRoleGroup: null, roleGroupRoles: [] }));
        break;
      case '/agreements':
        this.store.dispatch(agreementsActions.resetState({ selectedAgreement: null, logs: [], versions: [], selectedVersion: null }));
        break;
      case '/marketing/announcements':
        this.store.dispatch(announcementsActions.resetState({ selectedAnnouncement: null, affectedUsers: [], affectedUsersCount: 0 }));
        break;
      case '/marketing/topics':
        this.store.dispatch(topicsActions.resetState({ selectedTopic: null, subscribers: [], owners: [], selectedOwner: null }));
        break;
      case '/marketing/comments':
        this.store.dispatch(
          commentsActions.resetState({
            comments: [],
            selectedComment: null,
          }),
        );
        break;
      case '/marketing/news-articles':
        this.store.dispatch(regionsActions.resetState({ selectedRegion: null }));
        this.store.dispatch(
          newsArticlesActions.resetState({
            selectedNewsArticle: null,
            newsArticleComments: [],
            selectedNewsArticleRegionId: null,
          }),
        );
        break;
      case '/file-management/branches':
        this.store.dispatch(branchesActions.resetState({ selectedBranch: null }));
        break;
      case '/file-management/files':
        this.store.dispatch(filesActions.resetState({ selectedFile: null }));
        break;
      case '/products':
        this.store.dispatch(productsActions.resetState({ selectedProduct: null, files: [] }));
        break;
      case '/notifications/groups':
        this.store.dispatch(receiverGroupsActions.resetState({ selectedReceiverGroup: null, selectedCombinedEmail: null }));
        break;
      case '/notifications/template-groups':
        this.store.dispatch(
          templateGroupsActions.resetState({ selectedTemplateGroup: null, selectedAdditionalReceiver: null, additionalReceivers: [] }),
        );
        break;
      case '/notifications/templates':
        this.store.dispatch(
          templatesActions.resetState({
            selectedTemplate: null,
            selectedAdditionalReceiver: null,
            selectedMapping: null,
            selectedMappingContent: null,
            mappings: [],
            mappingContents: [],
            additionalReceivers: [],
          }),
        );
        break;
      case '/notifications/external-templates':
        this.store.dispatch(externalTemplatesActions.resetState({ selectedTemplate: null, selectedTag: null, tags: [] }));
        break;
      case '/notifications/logs':
        this.store.dispatch(logsActions.resetState({ selectedLog: null }));
        break;
      case '/assessments':
        this.store.dispatch(assessmentsActions.resetState({ selectedAssessment: null }));
        break;
      case '/customer-reports':
        this.store.dispatch(customerReportsActions.resetState({ selectedCustomerReport: null }));
        break;
      case '/common-data':
        this.store.dispatch(commonDataActions.resetState({ selectedCommonData: null }));
        this.store.dispatch(newsRegionsActions.resetState({ selectedNewsRegion: null }));
        break;
      case '/csp/tenants':
        this.store.dispatch(cspTenantsActions.resetState({ selectedTenant: null }));
        break;
      case '/marketing/regions':
        this.store.dispatch(regionsActions.resetState({ selectedRegion: null }));
        this.store.dispatch(newsArticlesActions.resetState({ newsArticlesByRegion: [] }));
        break;
      case '/actions/types':
        this.store.dispatch(actionTypesActions.resetState({ selectedActionType: null }));
        break;
      case undefined:
        if (customAction) {
          this.handleCustomActions(customAction);
        }
        break;
      default:
        break;
    }
  }

  private handleCustomActions(type: string): void {
    switch (type) {
      case 'MpnIdCheck':
        this.store.dispatch(cspUtilActions.resetState({ retrievedPartnerNameFromMicrosoft: null }));
        this.dialogService.openDialog<undefined, undefined>(MpnIdCheckDialogComponent);
        break;
      default:
        break;
    }
  }

  logout(): void {
    document.location.href = `/${environment.bffUrl}/auth/logout`;
  }

  private initRefreshTokenPolling(): void {
    this.subscribe(this.store.pipe(select(identitySelectors.selectTokenExpiration)), expires_at => {
      const currentTime = Date.now() / 1000; // Current time in seconds
      const timeUntilExpiration = expires_at - currentTime; // Time until expiration in seconds
      if (timeUntilExpiration > 300) {
        const refreshTimeout = (timeUntilExpiration - 300) * 1000;
        setTimeout(() => {
          this.store.dispatch(identityActions.refresh());
        }, refreshTimeout);
      }
    });
  }

  private findPageTitle(snapshot: ActivatedRouteSnapshot): string {
    if (snapshot.data['pageTitle']) {
      return snapshot.data['pageTitle'];
    } else if (snapshot.children.length) {
      return this.findPageTitle(snapshot.children[0]);
    } else {
      return '';
    }
  }
}
