import { HttpErrorResponse } from '@angular/common/http';
import { RegionListItem } from '@models/region';
import { createAction, props } from '@ngrx/store';
import { State } from './regions.reducer';

const NAMESPACE = '[Regions]';

export const getAllRegions = createAction(`${NAMESPACE} GetAllRegions`);
export const getAllRegionsComplete = createAction(`${NAMESPACE} GetAllRegionsComplete`, props<{ regions: RegionListItem[] }>());
export const getAllRegionsError = createAction(`${NAMESPACE} GetAllRegionsError`, props<{ err: HttpErrorResponse }>());

export const selectRegion = createAction(`${NAMESPACE} SelectRegion`, props<{ id: string }>());
export const selectRegionComplete = createAction(`${NAMESPACE} SelectRegionComplete`, props<{ region: RegionListItem }>());
export const selectRegionError = createAction(`${NAMESPACE} SelectRegionError`, props<{ err: HttpErrorResponse }>());

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
