import { createReducer, on } from '@ngrx/store';
import * as fromActions from './csp-util.actions';

export interface State {
  retrievedPartnerNameFromMicrosoft: string | null;
  isPartnerNameRetrievalFromMicrosoftLoading: boolean;
}

export const initialState: State = {
  retrievedPartnerNameFromMicrosoft: null,
  isPartnerNameRetrievalFromMicrosoftLoading: false,
};

export const cspUtilReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getPartnerNameByMpnId, state => ({ ...state, isPartnerNameRetrievalFromMicrosoftLoading: true })),
  on(fromActions.getPartnerNameByMpnIdComplete, (state, { res }) => ({
    ...state,
    isPartnerNameRetrievalFromMicrosoftLoading: false,
    retrievedPartnerNameFromMicrosoft: res.name,
  })),
  on(fromActions.getPartnerNameByMpnIdError, state => ({
    ...state,
    isPartnerNameRetrievalFromMicrosoftLoading: false,
    retrievedPartnerNameFromMicrosoft: null,
  })),
);
