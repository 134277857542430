import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { productsSelectors } from '@appState';
import { downloadBlobUrlFileToBrowser } from '@highlander/common/helpers/file.helpers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { BlobStorageService, isNotNullOrUndefined } from 'cui-components';
import { catchError, filter, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../../app.store';
import { FilesService } from '../../file-management/files/files.service';
import * as fromActions from './products.actions';
import { ProductsService } from './products.service';

@Injectable()
export class ProductsEffects {
  onGetProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(() =>
        this.productsService.getAll().pipe(
          map(products => fromActions.getAllComplete({ products })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onSelectProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectProduct),
      switchMap(({ id }) =>
        this.store.pipe(
          select(productsSelectors.selectProducts),
          take(1),
          switchMap(products => {
            if (products.length) {
              const product = products.find(p => p.id === id)!;
              return of(fromActions.selectProductComplete({ product }));
            } else {
              return this.productsService.get(id).pipe(
                map(product => fromActions.selectProductComplete({ product })),
                catchError(err => of(fromActions.selectProductError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onActivateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.activateProduct),
      switchMap(({ id }) =>
        this.productsService.activate(id).pipe(
          map(product => fromActions.activateProductComplete({ product })),
          catchError(err => of(fromActions.activateProductError({ err }))),
        ),
      ),
    ),
  );

  onDeactivateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deactivateProduct),
      switchMap(({ id }) =>
        this.productsService.deactivate(id).pipe(
          map(product => fromActions.deactivateProductComplete({ product })),
          catchError(err => of(fromActions.deactivateProductError({ err }))),
        ),
      ),
    ),
  );

  onActivateProductComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.activateProductComplete),
        tap(({ product }) => this.router.navigate(['/products', product.id, 'details'])),
      ),
    { dispatch: false },
  );

  onDeactivateProductComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.deactivateProductComplete),
        tap(({ product }) => this.router.navigate(['/products', product.id, 'details'])),
      ),
    { dispatch: false },
  );

  onUpdateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateProduct),
      switchMap(({ id, product }) =>
        this.productsService.update(id, product).pipe(
          map(product => fromActions.updateProductComplete({ product })),
          catchError(err => of(fromActions.updateProductError({ err }))),
        ),
      ),
    ),
  );

  onGetGenericFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllGenericFiles),
      switchMap(({ id }) =>
        this.productsService.getAllGenericFiles(id).pipe(
          map(files => fromActions.getAllGenericFilesComplete({ files })),
          catchError(err => of(fromActions.getAllGenericFilesError({ err }))),
        ),
      ),
    ),
  );

  onSelectGenericFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectGenericFile),
      switchMap(({ id, fileId }) =>
        this.store.pipe(
          select(productsSelectors.selectGenericFiles),
          take(1),
          switchMap(files => {
            if (files.length) {
              const file = files.find(f => f.id === fileId)!;
              return of(fromActions.selectGenericFileComplete({ file }));
            } else {
              return this.productsService.getOneGenericFile(id, fileId).pipe(
                map(file => fromActions.selectGenericFileComplete({ file })),
                catchError(err => of(fromActions.selectGenericFileError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onDownloadSelectedGenericFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadSelectedGenericFile),
      switchMap(() =>
        this.store.pipe(
          select(productsSelectors.selectSelectedGenericFileId),
          take(1),
          switchMap(id =>
            this.filesService.getDownloadUrl(id!).pipe(
              map(res => fromActions.downloadSelectedGenericFileComplete({ url: res.URL })),
              catchError(() => of(fromActions.downloadSelectedGenericFileError())),
            ),
          ),
        ),
      ),
    ),
  );

  onDownloadSelectedGenericFileComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.downloadSelectedGenericFileComplete),
        tap(({ url }) => {
          downloadBlobUrlFileToBrowser(url);
        }),
      ),
    { dispatch: false },
  );

  onDeleteSelectedGenericFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedGenericFile),
      switchMap(({ productId, fileId }) =>
        this.filesService.delete(fileId).pipe(
          map(() => fromActions.deleteSelectedGenericFileComplete({ id: productId })),
          catchError(err => of(fromActions.deleteSelectedGenericFileError({ err }))),
        ),
      ),
    ),
  );

  onDeleteSelectedGenericFileComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedGenericFileComplete),
      tap(({ id }) => this.router.navigate(['products', id, 'generic-files'])),
      map(id => fromActions.getAllGenericFiles(id)),
    ),
  );

  onAddGenericFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addGenericFile),
      switchMap(({ fileReq, file }) =>
        this.filesService.add(fileReq).pipe(
          map(response => fromActions.uploadGenericFile({ response, file })),
          catchError(err => of(fromActions.addGenericFileError({ err }))),
        ),
      ),
    ),
  );

  onAddGenericFileComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addGenericFileComplete),
        switchMap(({ fileRes }) =>
          this.store.pipe(
            select(productsSelectors.selectSelectedProduct),
            filter(isNotNullOrUndefined),
            take(1),
            tap(({ id }) => this.router.navigate(['products', id, 'generic-files', fileRes.id, 'details'])),
          ),
        ),
      ),
    { dispatch: false },
  );

  onUploadGenericFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.uploadGenericFile),
      switchMap(({ response, file }) =>
        this.blobStorageService.uploadFile(response.uploadURL, file).pipe(
          map(() => fromActions.activateGenericFile({ id: response.id })),
          catchError(err => of(fromActions.addGenericFileError({ err }))),
        ),
      ),
    ),
  );

  onActivateFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.activateGenericFile),
      switchMap(({ id }) =>
        this.filesService.activate(id).pipe(
          map(fileRes => fromActions.addGenericFileComplete({ fileRes })),
          catchError(err => of(fromActions.addGenericFileError({ err }))),
        ),
      ),
    ),
  );

  onGoToProductActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToProductActions),
        tap(({ id }) => this.router.navigate(['products', id])),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToListPage),
      tap(() => this.router.navigate(['products'])),
      map(() => fromActions.resetState({ selectedProduct: null })),
    ),
  );

  onGoToGenericFileActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToGenericFileActions),
        switchMap(({ fileId }) =>
          this.store.pipe(
            select(productsSelectors.selectSelectedProduct),
            filter(isNotNullOrUndefined),
            take(1),
            tap(({ id }) => this.router.navigate(['products', id, 'generic-files', fileId])),
          ),
        ),
      ),
    { dispatch: false },
  );

  onGoToGenericFileListPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToGenericFileListPage),
        switchMap(() =>
          this.store.pipe(
            select(productsSelectors.selectSelectedProduct),
            filter(isNotNullOrUndefined),
            take(1),
            tap(({ id }) => this.router.navigate(['products', id, 'generic-files'])),
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private productsService: ProductsService,
    private filesService: FilesService,
    private blobStorageService: BlobStorageService,
  ) {}
}
