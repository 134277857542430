import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetPartnerNameByMpnIdRequest, GetPartnerNameByMpnIdResponse } from '@models/csp/csp-util/csp-util.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CspUtilService {
  private readonly url = `${environment.bffUrl}/csp`;
  constructor(private httpClient: HttpClient) {}

  getPartnerNameByMpnId(req: GetPartnerNameByMpnIdRequest): Observable<GetPartnerNameByMpnIdResponse> {
    return this.httpClient.post<GetPartnerNameByMpnIdResponse>(`${this.url}/get-partner-name-by-mpn-id`, req);
  }
}
