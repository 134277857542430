import { ActivatedRoute, Router } from '@angular/router';

export function isNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

export function convertBooleanToYesNo(value: boolean): string {
  return value ? 'Yes' : 'No';
}

/** Use in getQuickFilterText
 * @returns an empty string to exclude column from search
 */
export function excludeColumnFromSearch(): string {
  return '';
}

export const formatSizeFromBytes = (size: number, decimalPlaces: number): string =>
  size > 1024 * 1024 ? +(size / (1024 * 1024)).toFixed(decimalPlaces) + ' MB' : +(size / 1024).toFixed(decimalPlaces) + ' KB';

export const routerGoBack = (router: Router, activatedRoute: ActivatedRoute): void => {
  // Start with the current activated route, transform it into url string
  const currentUrl = activatedRoute.pathFromRoot
    .map(segment => segment.snapshot.url.map(url => url.path).join('/'))
    .filter(Boolean)
    .join('/');

  // Split the URL into segments
  const urlSegments = currentUrl.split('/');

  if (urlSegments.length > 1) {
    // Remove the last segment to navigate one level up
    const parentUrl = urlSegments.slice(0, -1).join('/');
    router.navigateByUrl(`/${parentUrl}`);
  } else {
    // If there's no parent URL, navigate to the root
    router.navigate(['/']);
  }
};
