import { GroupedRoles } from '@models/authorization';
import { GUID } from '@models/common';
import { UserListItemResponse, UserResponse } from '@models/user';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './users.actions';

// Refactor me
export interface State extends EntityState<UserResponse | UserListItemResponse> {
  selectedUserId: GUID | null;
  isLoadingUser: boolean;

  selectedUserRoles: GroupedRoles[];
  isUserRolesLoading: boolean;

  changingAdditionalPartnerListInProgress: boolean;
  isLoadingUserList: boolean;
}

export const adapter: EntityAdapter<UserResponse | UserListItemResponse> = createEntityAdapter<UserResponse | UserListItemResponse>({
  selectId: (user: UserResponse | UserListItemResponse) => user.id,
});

export const initialState: State = adapter.getInitialState({
  selectedUserId: null,

  selectedUserRoles: [],
  isUserRolesLoading: false,

  isLoadingUser: false,
  changingAdditionalPartnerListInProgress: false,
  isLoadingUserList: false,
});

export const userReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),
  on(fromActions.query, state => ({ ...state, isLoadingUserList: true })),
  on(fromActions.queryComplete, (state, { users }) => adapter.setAll(users, { ...state, isLoadingUserList: false })),
  on(fromActions.queryError, state => adapter.removeAll({ ...state, isLoadingUserList: false })),

  on(fromActions.getUserById, (state, { userId }) => ({ ...state, selectedUserId: userId, selectedUserRoles: [], isLoadingUser: true })),
  on(fromActions.getUserByIdComplete, (state, { user }) => adapter.upsertOne(user, { ...state, isLoadingUser: false })),
  on(fromActions.getUserByIdError, state => ({ ...state, selectedUserId: null, isLoadingUser: false })),

  on(fromActions.getUserRoles, state => ({ ...state, isUserRolesLoading: true })),
  on(fromActions.getUserRolesComplete, (state, { roles }) => ({ ...state, selectedUserRoles: roles, isUserRolesLoading: false })),
  on(fromActions.getUserRolesError, state => ({ ...state, isUserRolesLoading: false })),

  on(fromActions.updateUserRoles, state => ({ ...state, isUserRolesLoading: true })),
  on(fromActions.updateUserRolesComplete, (state, { roles }) => ({
    ...state,
    selectedUserRoles: roles,
    isUserRolesLoading: false,
  })),
  on(fromActions.updateUserRolesError, state => ({ ...state, isUserRolesLoading: false })),

  on(fromActions.blockUnblockUser, state => ({
    ...state,
    isLoadingUser: true,
  })),
  on(fromActions.blockUnblockUserComplete, (state, { user }) => adapter.upsertOne(user, { ...state, isLoadingUser: false })),
  on(fromActions.blockUnblockUserError, state => ({
    ...state,
    isLoadingUser: false,
  })),

  on(fromActions.markForDeletion, state => ({
    ...state,
    isLoadingUser: true,
  })),
  on(fromActions.markForDeletionComplete, (state, { user }) => adapter.upsertOne(user, { ...state, isLoadingUser: false })),
  on(fromActions.markForDeletionError, state => ({
    ...state,
    isLoadingUser: false,
  })),

  on(fromActions.updateCrmAccId, state => ({
    ...state,
    isLoadingUser: true,
  })),
  on(fromActions.updateCrmAccIdComplete, (state, { user }) => adapter.upsertOne(user, { ...state, isLoadingUser: false })),
  on(fromActions.updateCrmAccIdError, state => ({
    ...state,
    isLoadingUser: false,
  })),

  on(fromActions.addAdditionalPartnerForUser, (state, { userId }) => ({
    ...state,
    selectedUserId: userId,
    isLoadingUser: true,
    changingAdditionalPartnerListInProgress: true,
  })),
  on(fromActions.addAdditionalPartnerForUserComplete, (state, { user }) =>
    adapter.upsertOne(user, { ...state, isLoadingUser: false, changingAdditionalPartnerListInProgress: false }),
  ),
  on(fromActions.addAdditionalPartnerForUserError, state => ({
    ...state,
    isLoadingUser: false,
    changingAdditionalPartnerListInProgress: false,
  })),

  on(fromActions.removeAdditionalPartnerFromUser, (state, { userId }) => ({
    ...state,
    selectedUserId: userId,
    isLoadingUser: true,
    changingAdditionalPartnerListInProgress: true,
  })),
  on(fromActions.removeAdditionalPartnerFromUserComplete, (state, { user }) =>
    adapter.upsertOne(user, { ...state, isLoadingUser: false, changingAdditionalPartnerListInProgress: false }),
  ),
  on(fromActions.removeAdditionalPartnerFromUserError, state => ({
    ...state,
    isLoadingUser: false,
    changingAdditionalPartnerListInProgress: false,
  })),

  on(fromActions.resetUserTwoFaById, state => ({ ...state, isLoadingUser: true })),
  on(fromActions.resetUserTwoFaComplete, state => ({ ...state, isLoadingUser: false })),
  on(fromActions.resetUserTwoFaError, state => ({ ...state, isLoadingUser: false })),

  on(fromActions.downloadPermissions, state => ({ ...state, isLoadingUser: true })),
  on(fromActions.downloadPermissionsComplete, state => ({ ...state, isLoadingUser: false })),
  on(fromActions.downloadPermissionsError, state => ({ ...state, isLoadingUser: false })),

  on(fromActions.clearUserList, state => adapter.removeAll(state)),
);
