import { AffectedUserListItem, Announcement, AnnouncementListItem, AnnouncementsToggleOptions } from '@models/index';
import { createReducer, on } from '@ngrx/store';
import { findAndRemove, findAndReplace, predicateForSorting, sortAlphabetically } from '../helpers/helper';
import * as fromActions from './announcements.actions';

export interface State {
  announcements: AnnouncementListItem[];
  isLoading: boolean;
  isLoadingSelectedAnnouncement: boolean;
  selectedAnnouncement: Announcement | null;
  toggle: AnnouncementsToggleOptions;

  affectedUsers: AffectedUserListItem[];
  isAffectedUsersLoading: boolean;
  affectedUsersCount: number;
}

export const initialState: State = {
  announcements: [],
  isLoading: false,
  isLoadingSelectedAnnouncement: false,
  selectedAnnouncement: null,
  toggle: AnnouncementsToggleOptions.All,

  affectedUsers: [],
  isAffectedUsersLoading: false,
  affectedUsersCount: 0,
};

export const announcementsReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, (state, { toggle }) => ({ ...state, toggle, isLoading: true })),
  on(fromActions.getAllComplete, (state, { announcements }) => ({
    ...state,
    announcements: sortAlphabetically<AnnouncementListItem>(announcements, predicateForSorting('subject')),
    isLoading: false,
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.getAnnouncement, state => ({ ...state, toggle: AnnouncementsToggleOptions.All, isLoadingSelectedAnnouncement: true })),
  on(fromActions.getAnnouncementComplete, (state, { announcement }) => ({
    ...state,
    isLoadingSelectedAnnouncement: false,
    selectedAnnouncement: announcement,
  })),
  on(fromActions.getAnnouncementError, state => ({ ...state, isLoadingSelectedAnnouncement: false })),

  on(fromActions.addNewAnnouncement, state => ({
    ...state,
    isLoading: true,
    isLoadingSelectedAnnouncement: true,
  })),
  on(fromActions.addNewAnnouncementComplete, (state, { announcement }) => ({
    ...state,
    selectedAnnouncement: announcement,
    announcements: sortAlphabetically<AnnouncementListItem>(
      [...state.announcements, convertToAnnouncementListItem(announcement)],
      predicateForSorting('subject'),
    ),
    isLoading: false,
    isLoadingSelectedAnnouncement: false,
  })),
  on(fromActions.addNewAnnouncementError, state => ({ ...state, isLoading: false, isLoadingSelectedAnnouncement: false })),

  on(fromActions.updateAnnouncement, state => ({ ...state, isLoadingSelectedAnnouncement: true, isLoading: true })),
  on(fromActions.updateAnnouncementComplete, (state, { announcement }) => ({
    ...state,
    announcements: sortAlphabetically<AnnouncementListItem>(
      findAndReplace<AnnouncementListItem>(
        state.announcements,
        convertToAnnouncementListItem(announcement),
        item => item.id === announcement.id,
      ),
      predicateForSorting('subject'),
    ),
    isLoadingSelectedAnnouncement: false,
    selectedAnnouncement: announcement,
    isLoading: false,
  })),
  on(fromActions.updateAnnouncementError, state => ({ ...state, isLoadingSelectedAnnouncement: false, isLoading: false })),

  on(fromActions.deleteAnnouncement, state => ({ ...state, isLoading: true })),
  on(fromActions.deleteAnnouncementComplete, (state, { id }) => ({
    ...state,
    announcements: sortAlphabetically<AnnouncementListItem>(
      findAndRemove<AnnouncementListItem>(state.announcements, item => item.id === id),
      predicateForSorting('subject'),
    ),
    isLoading: false,
  })),
  on(fromActions.deleteAnnouncementError, state => ({ ...state, isLoading: false })),

  on(fromActions.createUserListForAnnouncement, state => ({ ...state, isLoading: true })),
  on(fromActions.createUserListForAnnouncementComplete, (state, { announcement }) => ({
    ...state,
    announcements: sortAlphabetically<AnnouncementListItem>(
      findAndReplace<AnnouncementListItem>(
        state.announcements,
        convertToAnnouncementListItem(announcement),
        item => item.id === announcement.id,
      ),
      predicateForSorting('subject'),
    ),
    isLoading: false,
  })),
  on(fromActions.createUserListForAnnouncementError, state => ({ ...state, isLoading: false })),

  on(fromActions.requestForAnnouncement, state => ({ ...state, isLoading: true, isLoadingSelectedAnnouncement: true })),
  on(fromActions.requestForAnnouncementComplete, (state, { announcement }) => ({
    ...state,
    announcements: sortAlphabetically<AnnouncementListItem>(
      findAndReplace<AnnouncementListItem>(
        state.announcements,
        convertToAnnouncementListItem(announcement),
        item => item.id === announcement.id,
      ),
      predicateForSorting('subject'),
    ),
    selectedAnnouncement: announcement,
    isLoading: false,
    isLoadingSelectedAnnouncement: false,
  })),
  on(fromActions.requestForAnnouncementError, state => ({ ...state, isLoading: false })),

  on(fromActions.getAffectedUsers, state => ({ ...state, isAffectedUsersLoading: true })),
  on(fromActions.getAffectedUsersComplete, (state, { affectedUsers }) => ({
    ...state,
    affectedUsers: affectedUsers,
    isAffectedUsersLoading: false,
  })),
  on(fromActions.getAffectedUsersError, state => ({ ...state, isAffectedUsersLoading: false })),

  on(fromActions.addAffectedUser, state => ({ ...state, isAffectedUsersLoading: true })),
  on(fromActions.addAffectedUserComplete, (state, { affectedUsers }) => ({
    ...state,
    affectedUsers: [...state.affectedUsers, ...affectedUsers],
    isAffectedUsersLoading: false,
  })),
  on(fromActions.addAffectedUserError, state => ({ ...state, isAffectedUsersLoading: false })),

  on(fromActions.deleteAffectedUser, state => ({ ...state, isAffectedUsersLoading: true })),
  on(fromActions.deleteAffectedUserComplete, (state, { userId }) => ({
    ...state,
    affectedUsers: findAndRemove<AffectedUserListItem>(state.affectedUsers, item => item.id === userId),
    isAffectedUsersLoading: false,
  })),
  on(fromActions.deleteAffectedUserError, state => ({ ...state, isAffectedUsersLoading: false })),

  on(fromActions.getAffectedUsersCount, state => ({ ...state, isAffectedUsersLoading: true })),
  on(fromActions.getAffectedUsersCountComplete, (state, { count }) => ({
    ...state,
    affectedUsersCount: count,
    isAffectedUsersLoading: false,
  })),
  on(fromActions.getAffectedUsersCountError, state => ({ ...state, isAffectedUsersLoading: false })),
);

const convertToAnnouncementListItem = (announcement: Announcement): AnnouncementListItem => ({
  ...announcement,
  validFromDate: announcement.validFromDate ? new Date(announcement.validFromDate) : null,
  validToDate: announcement.validToDate ? new Date(announcement.validToDate) : null,
});
