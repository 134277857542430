import { HttpErrorResponse } from '@angular/common/http';
import { CommentData, CommentsQueryParams, NewsArticleComment } from '@models/news-article/news-article.model';
import { createAction, props } from '@ngrx/store';
import { State } from './comments.reducer';

const NAMESPACE = '[Comments]';

export const getAllComments = createAction(
  `${NAMESPACE} GetAllComments`,
  props<{ commentsQuery: CommentsQueryParams; resetSelectedComment?: boolean }>(),
);
export const getAllCommentsComplete = createAction(
  `${NAMESPACE} GetAllCommentsComplete`,
  props<{ comments: CommentData[]; resetSelectedComment?: boolean }>(),
);
export const getAllCommentsError = createAction(`${NAMESPACE} GetAllCommentsError`, props<{ err: HttpErrorResponse }>());

export const selectComment = createAction(`${NAMESPACE} SelectComment`, props<{ commentId: string; articleId: string }>());
export const selectCommentComplete = createAction(`${NAMESPACE} SelectCommentComplete`, props<{ comment: NewsArticleComment }>());
export const selectCommentError = createAction(`${NAMESPACE} SelectCommentError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedComment = createAction(`${NAMESPACE} DeleteSelectedComment`, props<{ articleId: string; commentId: string }>());
export const deleteSelectedCommentComplete = createAction(`${NAMESPACE} DeleteSelectedCommentComplete`, props<{ commentId: string }>());
export const deleteSelectedCommentError = createAction(`${NAMESPACE} DeleteSelectedCommentError`, props<{ err: HttpErrorResponse }>());

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());

export const openFilterModal = createAction(`${NAMESPACE} OpenFilterModal`);
export const submitFilterModal = createAction(`${NAMESPACE} SubmitFilterModal`, props<{ commentsQuery: CommentsQueryParams }>());

export const goToCommentDetails = createAction(`${NAMESPACE} GoToCommentDetails`, props<{ articleId: string; commentId: string }>());
export const goToCommentsListPage = createAction(`${NAMESPACE} GoToCommentsListPage`);
