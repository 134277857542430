import { HttpErrorResponse } from '@angular/common/http';
import { GroupedRoles, RoleType, UpdateRoleForEntityRequest } from '@models/authorization';
import { UserListItemParams, UserListItemResponse, UserResponse } from '@models/user';
import { createAction, props } from '@ngrx/store';
import { State } from './users.reducer';

export const ToggleOptions = ['Operative', 'All'] as const;
export type ToggleOptionsName = (typeof ToggleOptions)[number];

const NAMESPACE = '[Users]';

export const query = createAction(`${NAMESPACE} Query`, props<{ query: Partial<UserListItemParams> }>());
export const queryComplete = createAction(`${NAMESPACE} QueryComplete`, props<{ users: UserListItemResponse[] }>());
export const queryError = createAction(`${NAMESPACE} QueryError`);

export const getUserById = createAction(`${NAMESPACE} GetUserById`, props<{ userId: string }>());
export const getUserByIdComplete = createAction(`${NAMESPACE} GetUserByIdComplete`, props<{ user: UserResponse }>());
export const getUserByIdError = createAction(`${NAMESPACE} GetUserByIdError`);

export const resetUserTwoFaById = createAction(`${NAMESPACE} ResetUserTwoFaById`, props<{ userId: string }>());
export const resetUserTwoFaComplete = createAction(`${NAMESPACE} ResetUserTwoFaComplete`);
export const resetUserTwoFaError = createAction(`${NAMESPACE} ResetUserTwoFaError`);

export const downloadPermissions = createAction(
  `${NAMESPACE} DownloadPermissions`,
  props<{ id: string; partnerId: string; email: string }>(),
);
export const downloadPermissionsComplete = createAction(`${NAMESPACE} DownloadPermissionsComplete`);
export const downloadPermissionsError = createAction(`${NAMESPACE} DownloadPermissionsError`);

export const blockUnblockUser = createAction(`${NAMESPACE} BlockUnblockUser`, props<{ id: string; block: boolean }>());
export const blockUnblockUserComplete = createAction(`${NAMESPACE} BlockUnblockUserComplete`, props<{ user: UserResponse }>());
export const blockUnblockUserError = createAction(`${NAMESPACE} BlockUnblockUserError`);

export const markForDeletion = createAction(`${NAMESPACE} MarkForDeletion`, props<{ id: string }>());
export const markForDeletionComplete = createAction(`${NAMESPACE} MarkForDeletionComplete`, props<{ user: UserResponse }>());
export const markForDeletionError = createAction(`${NAMESPACE} MarkForDeletionError`);

export const updateCrmAccId = createAction(`${NAMESPACE} UpdateCrmAccId`, props<{ userId: string; crmContactId: string }>());
export const updateCrmAccIdComplete = createAction(`${NAMESPACE} UpdateCrmAccIdComplete`, props<{ user: UserResponse }>());
export const updateCrmAccIdError = createAction(`${NAMESPACE} UpdateCrmAccIdError`);

export const goToUserActions = createAction(`${NAMESPACE} GoToUserActions`, props<{ userId: string }>());
export const goToSelectedUserActions = createAction(`${NAMESPACE} GoToSelectedUserActions`);
export const goToUsersList = createAction(`${NAMESPACE} GoToUsersList`);

export const getUserRoles = createAction(`${NAMESPACE} GetUserRoles`, props<{ id: string; partnerId: string; filter: RoleType }>());
export const getUserRolesComplete = createAction(`${NAMESPACE} GetUserRolesComplete`, props<{ roles: GroupedRoles[] }>());
export const getUserRolesError = createAction(`${NAMESPACE} GetUserRolesError`, props<{ err: HttpErrorResponse }>());

export const updateUserRoles = createAction(
  `${NAMESPACE} UserRolesUpdate`,
  props<{ updatedRoles: UpdateRoleForEntityRequest[]; forUserId: string; forPartnerId: string; filter: RoleType }>(),
);
export const updateUserRolesComplete = createAction(`${NAMESPACE} UserRolesUpdateComplete`, props<{ roles: GroupedRoles[] }>());
export const updateUserRolesError = createAction(`${NAMESPACE} UserRolesUpdateError`, props<{ err: HttpErrorResponse }>());

export const addAdditionalPartnerForUser = createAction(
  `${NAMESPACE} AddAdditionalPartnerForUser`,
  props<{ userId: string; additionalPartnerId: string }>(),
);
export const addAdditionalPartnerForUserComplete = createAction(
  `${NAMESPACE} AddAdditionalPartnerForUserComplete`,
  props<{ user: UserResponse }>(),
);
export const addAdditionalPartnerForUserError = createAction(`${NAMESPACE} AddAdditionalPartnerForUserError`);

export const removeAdditionalPartnerFromUser = createAction(
  `${NAMESPACE} RemoveAdditionalPartnerFromUser`,
  props<{ userId: string; additionalPartnerId: string }>(),
);
export const removeAdditionalPartnerFromUserComplete = createAction(
  `${NAMESPACE} RemoveAdditionalPartnerFromUserComplete`,
  props<{ user: UserResponse }>(),
);
export const removeAdditionalPartnerFromUserError = createAction(`${NAMESPACE} RemoveAdditionalPartnerFromUserError`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
export const clearUserList = createAction(`${NAMESPACE} ClearUserList`);
