import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.regions);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectRegions = createSelector(selectModuleState, state => state.regions);

export const selectIsSelectedRegionLoading = createSelector(selectModuleState, state => state.isSelectedRegionLoading);
export const selectSelectedRegion = createSelector(selectModuleState, state => state.selectedRegion);
export const selectSelectedRegionId = createSelector(selectModuleState, state => state.selectedRegion && state.selectedRegion.id);
