import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GroupedRoles } from '@models/authorization';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { NotificationsService } from 'cui-components';
import { catchError, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../app.store';
import * as fromActions from './users.actions';
import * as fromSelectors from './users.selector';
import { UsersService } from './users.service';

@Injectable()
export class UsersEffects {
  onGetUsersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.query),
      switchMap(({ query }) =>
        this.userService.getUsers(query).pipe(
          map(users => fromActions.queryComplete({ users })),
          catchError(() => of(fromActions.queryError())),
        ),
      ),
    ),
  );
  onGetUserById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUserById),
      switchMap(({ userId }) =>
        this.userService.getUser(userId).pipe(
          map(user => fromActions.getUserByIdComplete({ user })),
          catchError(() => of(fromActions.getUserByIdError())),
        ),
      ),
    ),
  );

  onGetRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUserRoles),
      switchMap(({ id, partnerId, filter }) =>
        this.userService.getUserRoles(id, partnerId, filter).pipe(
          map(roles => fromActions.getUserRolesComplete({ roles })),
          catchError(err => of(fromActions.getUserRolesError({ err }))),
        ),
      ),
    ),
  );

  onChangeUserRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateUserRoles),
      switchMap(({ updatedRoles, forUserId, forPartnerId, filter }) =>
        this.userService.setUserRoles(forUserId, forPartnerId, updatedRoles, filter).pipe(
          map((roles: GroupedRoles[]) => fromActions.updateUserRolesComplete({ roles })),
          catchError(err => of(fromActions.updateUserRolesError({ err }))),
        ),
      ),
    ),
  );

  onRemoveAdditionalPartnerFromUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeAdditionalPartnerFromUser),
      switchMap(({ userId, additionalPartnerId }) =>
        this.userService.removeAdditionalPartner(userId, additionalPartnerId).pipe(
          map(user => fromActions.removeAdditionalPartnerFromUserComplete({ user })),
          catchError(() => of(fromActions.removeAdditionalPartnerFromUserError())),
        ),
      ),
    ),
  );

  onAddAdditionalPartnerFromUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addAdditionalPartnerForUser),
      switchMap(({ userId, additionalPartnerId }) =>
        this.userService.addAdditionalPartner(userId, additionalPartnerId).pipe(
          map(user => fromActions.addAdditionalPartnerForUserComplete({ user })),
          catchError(() => of(fromActions.addAdditionalPartnerForUserError())),
        ),
      ),
    ),
  );

  onResetUserTwoFaById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resetUserTwoFaById),
      switchMap(({ userId }) =>
        this.userService.resetUserTwoFa(userId).pipe(
          map(() => fromActions.resetUserTwoFaComplete()),
          catchError(() => of(fromActions.resetUserTwoFaError())),
        ),
      ),
    ),
  );

  onDownloadUserPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadPermissions),
      switchMap(({ id, partnerId, email }) =>
        this.userService.getUserPermissions(id, partnerId).pipe(
          tap(permissions => {
            const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(permissions, null, 2));
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', dataStr);
            downloadAnchorNode.setAttribute('download', `${email}-permissions` + '.json');
            document.body.appendChild(downloadAnchorNode); // required for firefox
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
          }),
          map(() => fromActions.downloadPermissionsComplete()),
          catchError(() => of(fromActions.downloadPermissionsError())),
        ),
      ),
    ),
  );

  onResetUserTwoFaComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.resetUserTwoFaComplete),
        tap(() => this.notifications.showNotification('2FA resetted sucessfully')),
      ),
    { dispatch: false },
  );

  onBlockUnblockUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.blockUnblockUser),
      switchMap(({ id, block }) =>
        this.userService.blockUnblockUser(id, block).pipe(
          map(user => fromActions.blockUnblockUserComplete({ user })),
          catchError(() => of(fromActions.blockUnblockUserError())),
        ),
      ),
    ),
  );

  onMarkForDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.markForDeletion),
      switchMap(({ id }) =>
        this.userService.markForDeletion(id).pipe(
          map(user => {
            this.router.navigate(['/', 'users', user.id]);
            return fromActions.markForDeletionComplete({ user });
          }),
          catchError(() => of(fromActions.markForDeletionError())),
        ),
      ),
    ),
  );

  onUpdateUserCrmContactId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateCrmAccId),
      switchMap(({ userId, crmContactId }) =>
        this.userService.updateCrmContactId(userId, crmContactId).pipe(
          map(user => fromActions.updateCrmAccIdComplete({ user })),
          catchError(() => of(fromActions.updateCrmAccIdError())),
        ),
      ),
    ),
  );

  onGoToUserActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToUserActions),
        tap(({ userId }) => this.router.navigate(['/', 'users', userId])),
      ),
    { dispatch: false },
  );

  onGoToSelectedUserActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToSelectedUserActions),
        switchMap(() =>
          this.store.pipe(
            select(fromSelectors.selectCurrentUserId),
            take(1),
            tap(id => this.router.navigate(['users', id])),
          ),
        ),
      ),
    { dispatch: false },
  );

  onGoToUserList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToUsersList),
      tap(() => this.router.navigate(['/', 'users'])),
      map(() => fromActions.resetState({ selectedUserId: null })),
    ),
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private userService: UsersService,
    private store: Store<AppState>,
    private notifications: NotificationsService,
  ) {}
}
