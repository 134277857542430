import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.cspUtil);

export const selectRetrievedPartnerNameFromMicrosoft = createSelector(selectModuleState, state => state.retrievedPartnerNameFromMicrosoft);
export const selectIsPartnerNameRetrievalFromMicrosoftLoading = createSelector(
  selectModuleState,
  state => state.isPartnerNameRetrievalFromMicrosoftLoading,
);
