import { HttpErrorResponse } from '@angular/common/http';

const errorCodesRequiringReauthentication: string[] = [
  '403-203', // refresh token not found in keyvault
  '403-205', // refresh token expired or otherwise invalid
  '500-206', // application id object error
];

export const requiresCspTenantReauthentication = (error: HttpErrorResponse): boolean => {
  if (!error || !error.url) {
    return false;
  }
  const regexResult = error.url!.match(/csp\/tenants\/[a-z0-9-]{1,40}\/secret/);
  return !!regexResult && errorCodesRequiringReauthentication.indexOf(error.error.errorCode) > -1;
};

export const partnerNotFoundByMpnId = (error: HttpErrorResponse): boolean => {
  if (!error || !error.url) {
    return false;
  }
  const regexResult = error.url!.match(/csp\/get-partner-name-by-mpn-id/);
  return !!regexResult;
};
