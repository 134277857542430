import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.newsArticles);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectIsLoadingByRegion = createSelector(selectModuleState, state => state.newsArticlesByRegionLoading);
export const selectNewsArticles = createSelector(selectModuleState, state => state.newsArticles);
export const selectNewsArticlesByRegion = createSelector(selectModuleState, state => state.newsArticlesByRegion);
export const selectActiveNewsArticles = createSelector(selectModuleState, state =>
  state.newsArticles.filter(item => item.status === 'Active'),
);

export const selectSelectedNewsArticle = createSelector(selectModuleState, state => state.selectedNewsArticle);
export const selectSelectedNewsArticleId = createSelector(selectModuleState, state => state.selectedNewsArticle?.id ?? null);
export const selectSelectedNewsArticleLoading = createSelector(selectModuleState, state => state.isSelectedNewsArticleLoading);

export const selectCreateNewsArticleInProgress = createSelector(selectModuleState, state => state.isNewsArticleCreateInProgress);

export const selectNewsArticleComments = createSelector(selectModuleState, state => state.newsArticleComments);
export const selectNewsArticleCommentsLoading = createSelector(selectModuleState, state => state.newsArticleCommentsLoading);

export const selectSelectedNewsArticleRegionId = createSelector(selectModuleState, state => state.selectedNewsArticleRegionId);
export const selectSelectedNewsArticleRegion = createSelector(selectModuleState, state => {
  if (state.selectedNewsArticle && state.selectedNewsArticle.regions.length) {
    return state.selectedNewsArticle.regions.find(item => item.id === state.selectedNewsArticleRegionId) ?? null;
  } else {
    return null;
  }
});

export const selectArticlesWithoutSelectedRegion = createSelector(selectModuleState, state => state.articlesWithoutSelectedRegion);
export const selectArticlesWithoutSelectedRegionLoading = createSelector(
  selectModuleState,
  state => state.articlesWithoutSelectedRegionLoading,
);
