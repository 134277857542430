import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import * as fromActions from './customer-reports.actions';
import { CustomerReportsService } from './customer-reports.service';
@Injectable()
export class CustomerReportsEffects {
  onGetCustomerReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(() =>
        this.customerReportsService.getAll().pipe(
          map(customerReports => fromActions.getAllComplete({ customerReports })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onAddCustomerReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addCustomerReport),
      switchMap(({ customerReport }) =>
        this.customerReportsService.add(customerReport).pipe(
          map(customerReport => fromActions.addCustomerReportComplete({ customerReport })),
          catchError(err => of(fromActions.addCustomerReportError({ err }))),
        ),
      ),
    ),
  );

  onAddCustomerReportComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addCustomerReportComplete),
        tap(response => this.router.navigate(['customer-reports', response.customerReport.id, 'details'])),
      ),
    { dispatch: false },
  );

  onSelectCustomerReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectCustomerReport),
      switchMap(({ id }) =>
        this.customerReportsService.get(id).pipe(
          map(customerReport => fromActions.selectCustomerReportComplete({ customerReport })),
          catchError(err => of(fromActions.selectCustomerReportError({ err }))),
        ),
      ),
    ),
  );

  onGoToCustomerReportActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToCustomerReportActions),
        tap(({ id }) => this.router.navigate(['customer-reports', id])),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToListPage),
      tap(() => this.router.navigate(['customer-reports'])),
      map(() => fromActions.resetState({ selectedCustomerReport: null })),
    ),
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private customerReportsService: CustomerReportsService,
  ) {}
}
