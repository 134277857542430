import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as fromActions from './csp-util.actions';
import { CspUtilService } from './csp-util.service';
@Injectable()
export class CspUtilEffects {
  onGetPartnerNameByMpnId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getPartnerNameByMpnId),
      switchMap(({ req }) =>
        this.cspUtilService.getPartnerNameByMpnId(req).pipe(
          map(res => fromActions.getPartnerNameByMpnIdComplete({ res })),
          catchError(err => of(fromActions.getPartnerNameByMpnIdError({ err }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private cspUtilService: CspUtilService,
  ) {}
}
