import { CspTenant } from '@models/csp/tenants/tenant.model';
import { createReducer, on } from '@ngrx/store';
import { findAndReplace, predicateForSorting, sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './tenants.actions';

export interface State {
  isLoading: boolean;
  tenantCreateLoading: boolean;
  tenants: CspTenant[];

  selectedTenant: CspTenant | null;
  selectedTenantLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  tenantCreateLoading: false,
  tenants: [],

  selectedTenant: null,
  selectedTenantLoading: false,
};

export const tenantsReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllComplete, (state, { tenants }) => ({
    ...state,
    tenants: sortAlphabetically(tenants, predicateForSorting('name')),
    isLoading: false,
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.addTenant, state => ({ ...state, tenantCreateLoading: true, isLoading: true })),
  on(fromActions.addTenantComplete, (state, { tenant }) => ({
    ...state,
    isLoading: false,
    tenantCreateLoading: false,
    tenants: sortAlphabetically([...state.tenants, tenant], predicateForSorting('name')),
  })),
  on(fromActions.addTenantError, state => ({ ...state, isLoading: false, tenantCreateLoading: false })),

  on(fromActions.updateTenant, state => ({ ...state, selectedTenantLoading: true, isLoading: true })),
  on(fromActions.updateTenantComplete, (state, { tenant }) => ({
    ...state,
    isLoading: false,
    selectedTenantLoading: false,
    selectedTenant: tenant,
  })),
  on(fromActions.updateTenantError, state => ({
    ...state,
    selectedTenant: { ...state.selectedTenant! },
    isLoading: false,
    selectedTenantLoading: false,
  })),

  on(fromActions.renewTenantSecret, state => ({ ...state, selectedTenantLoading: true, isLoading: true })),
  on(fromActions.renewTenantSecretComplete, (state, { tenant }) => ({
    ...state,
    isLoading: false,
    selectedTenantLoading: false,
    selectedTenant: tenant,
    tenants: sortAlphabetically(
      findAndReplace<CspTenant>(state.tenants, tenant, item => item.id === tenant.id),
      predicateForSorting('name'),
    ),
  })),
  on(fromActions.renewTenantSecretError, state => ({
    ...state,
    selectedTenant: { ...state.selectedTenant! },
    isLoading: false,
    selectedTenantLoading: false,
  })),

  on(fromActions.selectTenant, state => ({ ...state, selectedTenantLoading: true })),
  on(fromActions.selectTenantComplete, (state, { tenant }) => ({ ...state, selectedTenantLoading: false, selectedTenant: tenant })),
  on(fromActions.selectTenantError, state => ({ ...state, selectedTenantLoading: false })),

  on(fromActions.deleteSelectedTenant, state => ({ ...state, selectedTenantLoading: true })),
  on(fromActions.deleteSelectedTenantComplete, state => ({
    ...state,
    selectedTenantLoading: false,
    selectedTenant: null,
  })),
  on(fromActions.deleteSelectedTenantError, state => ({
    ...state,
    selectedTenantLoading: false,
  })),
);
