import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.products);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectProducts = createSelector(selectModuleState, state => state.products);

export const selectSelectedProduct = createSelector(selectModuleState, state => state.selectedProduct);
export const selectSelectedProductId = createSelector(selectModuleState, state => state.selectedProduct && state.selectedProduct.id);
export const selectSelectedProductLoading = createSelector(selectModuleState, state => state.selectedProductLoading);

export const selectGenericFiles = createSelector(selectModuleState, state => state.files);
export const selectGenericFilesLoading = createSelector(selectModuleState, state => state.filesLoading);

export const selectSelectedGenericFile = createSelector(selectModuleState, state => state.selectedFile);
export const selectSelectedGenericFileId = createSelector(selectModuleState, state => state.selectedFile && state.selectedFile.id);
export const selectSelectedGenericFileLoading = createSelector(selectModuleState, state => state.selectedFileLoading);
export const genericFileCreateInProgress = createSelector(selectModuleState, state => state.fileCreateInProgress);
