import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { catchError, map, of, switchMap, take, tap } from 'rxjs';
import { AppState } from '../app.store';
import * as fromActions from './partners.actions';
import * as fromSelectors from './partners.selector';
import { PartnersService } from './partners.service';

@Injectable()
export class PartnersEffects {
  onGetPartners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAll),
      switchMap(({ toggle }) =>
        this.partnersService.getPartners(toggle === 'All').pipe(
          map(partners => fromActions.getAllComplete({ partners })),
          catchError(err => of(fromActions.getAllError({ err }))),
        ),
      ),
    ),
  );

  onSelectPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectPartner),
      switchMap(({ id }) =>
        this.partnersService.getPartner(id).pipe(
          map(partner => fromActions.selectPartnerComplete({ partner })),
          catchError(err => of(fromActions.selectPartnerError({ err }))),
        ),
      ),
    ),
  );

  onGetPartnerBusinessData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getPartnerBusinessData),
      switchMap(({ id }) =>
        this.partnersService.getPartnerBusinessData(id).pipe(
          map(businessData => fromActions.getPartnerBusinessDataComplete({ businessData })),
          catchError(err => of(fromActions.getPartnerBusinessDataError({ err }))),
        ),
      ),
    ),
  );

  onSetPartnerRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setPartnerRoles),
      switchMap(({ partnerId, roles, filter }) =>
        this.partnersService.setPartnerRoles(partnerId, roles, filter).pipe(
          map(roles => fromActions.setPartnerRolesComplete({ roles })),
          catchError(err => of(fromActions.setPartnerRolesError({ err }))),
        ),
      ),
    ),
  );

  onGetPartnerRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getPartnerRoles),
      switchMap(({ partnerId, filter }) =>
        this.partnersService.getPartnerRoles(partnerId, filter).pipe(
          map(roles => fromActions.setPartnerRolesComplete({ roles })),
          catchError(err => of(fromActions.setPartnerRolesError({ err }))),
        ),
      ),
    ),
  );

  onMarkForDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.markForDeletion),
      switchMap(({ id }) =>
        this.partnersService.markForDeletion(id).pipe(
          map(() => fromActions.markForDeletionComplete()),
          catchError(err => of(fromActions.markForDeletionError({ err }))),
        ),
      ),
    ),
  );

  onSetCrmAccId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setCrmAccId),
      switchMap(({ crmAccountId, partnerId }) =>
        this.partnersService.setCrmAccId(partnerId, crmAccountId).pipe(
          map(partner => fromActions.setCrmAccIdComplete({ partner })),
          catchError(err => of(fromActions.markForDeletionError({ err }))),
        ),
      ),
    ),
  );

  onMarkForDeletionComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.markForDeletionComplete),
      tap(() => this.router.navigate(['partners'])),
      switchMap(() =>
        this.store.pipe(
          select(fromSelectors.selectToggle),
          take(1),
          map(toggle => fromActions.getAll({ toggle })),
        ),
      ),
    ),
  );

  onGetAdminUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAdminUsers),
      switchMap(({ partnerId }) =>
        this.partnersService.getAdminUsers(partnerId).pipe(
          map(admins => fromActions.getAdminUsersComplete({ admins })),
          catchError(err => of(fromActions.getAdminUsersError({ err }))),
        ),
      ),
    ),
  );

  onGoToPartnersActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToPartnerActions),
        tap(({ id }) => this.router.navigate(['partners', id])),
      ),
    { dispatch: false },
  );

  onGoToSelectedPartnersActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToSelectedPartnerActions),
        switchMap(() =>
          this.store.pipe(
            select(fromSelectors.selectCurrentPartnerId),
            take(1),
            tap(id => this.router.navigate(['partners', id])),
          ),
        ),
      ),
    { dispatch: false },
  );

  onGoToPartnerList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToPartnerList),
      tap(() => this.router.navigate(['partners'])),
      map(() => fromActions.resetState({ selectedPartner: null })),
    ),
  );

  onSetPartnerEntraTenantId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setPartnerEntraTenantId),
      switchMap(({ partnerId, req }) =>
        this.partnersService.setEntraTenantId(partnerId, req).pipe(
          map(partner => fromActions.setPartnerEntraTenantIdComplete({ partner })),
          catchError(err => of(fromActions.setPartnerEntraTenantIdError({ err }))),
        ),
      ),
    ),
  );

  onGetIndirectProviders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getIndirectProviders),
      switchMap(({ partnerId }) =>
        this.partnersService.getAllIndirectProviders(partnerId).pipe(
          map(indirectProviders => fromActions.getIndirectProvidersComplete({ indirectProviders })),
          catchError(err => of(fromActions.getIndirectProvidersError({ err }))),
        ),
      ),
    ),
  );

  onAddIndirectProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addIndirectProvider),
      switchMap(({ partnerId, req }) =>
        this.partnersService.createIndirectProvider(partnerId, req).pipe(
          map(indirectProvider => fromActions.addIndirectProviderComplete({ indirectProvider })),
          catchError(err => of(fromActions.addIndirectProviderError({ err }))),
        ),
      ),
    ),
  );

  onAddIndirectProviderComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addIndirectProviderComplete),
        switchMap(({ indirectProvider }) => {
          return this.router.navigate([
            'partners',
            indirectProvider.partnerId,
            'indirect-providers',
            indirectProvider.cspIndirectProviderTenantId,
            'details',
          ]);
        }),
      ),
    { dispatch: false },
  );

  onDeleteIndirectProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedIndirectProvider),
      switchMap(({ partnerId, indirectProviderId }) =>
        this.partnersService.deleteIndirectProvider(partnerId, indirectProviderId).pipe(
          map(() => fromActions.deleteSelectedIndirectProviderComplete({ partnerId })),
          catchError(err => of(fromActions.deleteSelectedIndirectProviderError({ err }))),
        ),
      ),
    ),
  );

  onDeleteIndirectProviderComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedIndirectProviderComplete),
      tap(({ partnerId }) => this.router.navigate(['partners', partnerId, 'indirect-providers'])),
      map(({ partnerId }) => fromActions.getIndirectProviders({ partnerId })),
    ),
  );

  onSelectIndirectProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectIndirectProvider),
      switchMap(({ partnerId, indirectProviderId }) =>
        this.store.pipe(
          select(fromSelectors.selectIndirectProviders),
          take(1),
          switchMap(indirectProviders => {
            const indirectProvider = indirectProviders.find(iP => iP.cspIndirectProviderTenantId === indirectProviderId);
            if (indirectProvider) {
              return of(fromActions.selectIndirectProviderComplete({ indirectProvider }));
            } else {
              return this.partnersService.getOneIndirectProvider(partnerId, indirectProviderId).pipe(
                map(indirectProvider => fromActions.selectIndirectProviderComplete({ indirectProvider })),
                catchError(err => of(fromActions.selectIndirectProviderError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  onGoToIndirectProviderActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToIndirectProviderActions),
        tap(({ partnerId, indirectProviderId }) => {
          this.router.navigate(['partners', partnerId, 'indirect-providers', indirectProviderId]);
        }),
      ),
    { dispatch: false },
  );

  onGoToIndirectProviderListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToIndirectProviderListPage),
      tap(({ partnerId }) => this.router.navigate(['partners', partnerId, 'indirect-providers'])),
      map(() => fromActions.resetState({ selectedIndirectProvider: null })),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly partnersService: PartnersService,
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) {}
}
