import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.comments);

export const selectSelectedComment = createSelector(selectModuleState, state => state.selectedComment);
export const selectSelectedCommentId = createSelector(selectModuleState, state => state.selectedComment?.id ?? null);
export const selectSelectedCommentLoading = createSelector(selectModuleState, state => state.isSelectedCommentLoading);

export const selectComments = createSelector(selectModuleState, state => state.comments);
export const selectCommentsLoading = createSelector(selectModuleState, state => state.commentsLoading);
