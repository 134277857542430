import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileResponse } from '@models/index';
import { Product, ProductUpdateRequest } from '@models/products-and-orders/products';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProductsService {
  private readonly url = `${environment.bffUrl}/products`;
  constructor(private httpClient: HttpClient) {}

  getAll(includeMarkedForDeletion = false): Observable<Product[]> {
    return this.httpClient.get<Product[]>(this.url, { params: { includeMarkedForDeletion } });
  }

  get(id: string, includeMarkedForDeletion = false): Observable<Product> {
    return this.httpClient.get<Product>(`${this.url}/${id}`, { params: { includeMarkedForDeletion } });
  }

  update(id: string, product: ProductUpdateRequest): Observable<Product> {
    return this.httpClient.put<Product>(`${this.url}/${id}`, product);
  }

  activate(id: string): Observable<Product> {
    return this.httpClient.post<Product>(`${this.url}/${id}/activate`, null);
  }

  deactivate(id: string): Observable<Product> {
    return this.httpClient.post<Product>(`${this.url}/${id}/deactivate`, null);
  }

  getAllGenericFiles(id: string): Observable<FileResponse[]> {
    return this.httpClient.get<FileResponse[]>(`${this.url}/${id}/generic-files `);
  }

  getOneGenericFile(id: string, fileId: string): Observable<FileResponse> {
    return this.httpClient.get<FileResponse>(`${this.url}/${id}/generic-files/${fileId} `);
  }
}
