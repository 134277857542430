import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.сustomerReports);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectIsCustomerReportCreateLoading = createSelector(selectModuleState, state => state.customerReportCreateLoading);
export const selectCustomerReports = createSelector(selectModuleState, state => state.customerReports);

export const selectSelectedCustomerReport = createSelector(selectModuleState, state => state.selectedCustomerReport);
export const selectSelectedCustomerReportId = createSelector(
  selectModuleState,
  state => state.selectedCustomerReport && state.selectedCustomerReport.id,
);
export const selectSelectedCustomerReportLoading = createSelector(selectModuleState, state => state.selectedCustomerReportLoading);
