import { FileResponse } from '@models/index';
import { Product } from '@models/products-and-orders/products';
import { createReducer, on } from '@ngrx/store';
import { findAndReplace, predicateForSorting, sortAlphabetically } from '../../helpers/helper';
import * as fromActions from './products.actions';

export interface State {
  isLoading: boolean;
  products: Product[];

  selectedProduct: Product | null;
  selectedProductLoading: boolean;

  files: FileResponse[];
  filesLoading: boolean;

  selectedFile: FileResponse | null;
  selectedFileLoading: boolean;
  fileCreateInProgress: boolean;
}

export const initialState: State = {
  isLoading: false,
  products: [],

  selectedProduct: null,
  selectedProductLoading: false,

  files: [],
  filesLoading: false,

  selectedFile: null,
  selectedFileLoading: false,
  fileCreateInProgress: false,
};

export const productsReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAll, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllComplete, (state, { products }) => ({
    ...state,
    products: sortAlphabetically(products, predicateForSorting('name')),
    isLoading: false,
  })),
  on(fromActions.getAllError, state => ({ ...state, isLoading: false })),

  on(fromActions.updateProduct, state => ({ ...state, selectedProductLoading: true, isLoading: true })),
  on(fromActions.updateProductComplete, (state, { product }) => ({
    ...state,
    isLoading: false,
    selectedProductLoading: false,
    selectedProduct: product,
    products: sortAlphabetically(
      findAndReplace<Product>(state.products, product, item => item.id === product.id),
      predicateForSorting('name'),
    ),
  })),
  on(fromActions.updateProductError, state => ({
    ...state,
    selectedProduct: { ...state.selectedProduct! },
    isLoading: false,
    selectedProductLoading: false,
  })),

  on(fromActions.activateProduct, state => ({ ...state, selectedProductLoading: true })),
  on(fromActions.activateProductComplete, (state, { product }) => ({
    ...state,
    selectedProductLoading: false,
    selectedProduct: product,
    products: sortAlphabetically(
      findAndReplace<Product>(state.products, product, item => item.id === product.id),
      predicateForSorting('name'),
    ),
  })),
  on(fromActions.activateProductError, state => ({ ...state, selectedProductLoading: false })),

  on(fromActions.deactivateProduct, state => ({ ...state, selectedProductLoading: true })),
  on(fromActions.deactivateProductComplete, (state, { product }) => ({
    ...state,
    selectedProductLoading: false,
    selectedProduct: product,
    products: sortAlphabetically(
      findAndReplace<Product>(state.products, product, item => item.id === product.id),
      predicateForSorting('name'),
    ),
  })),
  on(fromActions.deactivateProductError, state => ({ ...state, selectedProductLoading: false })),

  on(fromActions.selectProduct, state => ({ ...state, selectedProductLoading: true })),
  on(fromActions.selectProductComplete, (state, { product }) => ({ ...state, selectedProductLoading: false, selectedProduct: product })),
  on(fromActions.selectProductError, state => ({ ...state, selectedProductLoading: false })),

  on(fromActions.getAllGenericFiles, state => ({ ...state, filesLoading: true })),
  on(fromActions.getAllGenericFilesComplete, (state, { files }) => ({
    ...state,
    files: sortAlphabetically(files, predicateForSorting('fileName')),
    filesLoading: false,
  })),
  on(fromActions.getAllGenericFilesError, state => ({ ...state, filesLoading: false })),

  on(fromActions.selectGenericFile, state => ({ ...state, selectedFileLoading: true })),
  on(fromActions.selectGenericFileComplete, (state, { file }) => ({
    ...state,
    selectedFile: file,
    selectedFileLoading: false,
  })),
  on(fromActions.selectGenericFileError, state => ({ ...state, selectedFileLoading: false })),

  on(fromActions.deleteSelectedGenericFile, state => ({ ...state, filesLoading: true })),
  on(fromActions.deleteSelectedGenericFileComplete, state => ({ ...state, filesLoading: false })),
  on(fromActions.deleteSelectedGenericFileError, state => ({ ...state, filesLoading: false })),

  on(fromActions.addGenericFile, state => ({ ...state, fileCreateInProgress: true, isLoading: true })),
  on(fromActions.addGenericFileComplete, (state, { fileRes }) => ({
    ...state,
    isLoading: false,
    fileCreateInProgress: false,
    files: sortAlphabetically([...state.files, fileRes], predicateForSorting('fileName')),
  })),
  on(fromActions.addGenericFileError, state => ({ ...state, isLoading: false, fileCreateInProgress: false })),

  on(fromActions.uploadGenericFile, state => ({ ...state })),
);
