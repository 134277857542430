import { Injectable } from '@angular/core';
import { regionsSelectors } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, of, switchMap, take } from 'rxjs';
import { AppState } from '../app.store';
import * as fromActions from './regions.actions';
import { RegionsService } from './regions.service';

@Injectable()
export class RegionsEffect {
  onGetRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllRegions),
      switchMap(() =>
        this.service.getAll().pipe(
          map(regions => fromActions.getAllRegionsComplete({ regions })),
          catchError(err => of(fromActions.getAllRegionsError({ err }))),
        ),
      ),
    ),
  );

  onSelectRegion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectRegion),
      switchMap(({ id }) =>
        this.store.pipe(
          select(regionsSelectors.selectRegions),
          take(1),
          switchMap(regions => {
            if (regions.length) {
              const region = regions.find(r => r.id === id)!;
              return of(fromActions.selectRegionComplete({ region }));
            } else {
              return this.service.getOne(id).pipe(
                map(region => fromActions.selectRegionComplete({ region })),
                catchError(err => of(fromActions.selectRegionError({ err }))),
              );
            }
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly service: RegionsService,
    private readonly store: Store<AppState>,
  ) {}
}
