import { RegionListItem } from '@models/index';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './regions.actions';

export interface State {
  isLoading: boolean;
  regions: RegionListItem[];

  isSelectedRegionLoading: boolean;
  selectedRegion: RegionListItem | null;
}

export const initialState: State = {
  isLoading: false,
  regions: [],

  isSelectedRegionLoading: false,
  selectedRegion: null,
};

export const regionsReducer = createReducer(
  initialState,
  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAllRegions, state => ({ ...state, isLoading: true })),
  on(fromActions.getAllRegionsComplete, (state, { regions }) => ({
    ...state,
    isLoading: false,
    regions,
  })),
  on(fromActions.getAllRegionsError, state => ({ ...state, isLoading: false })),

  on(fromActions.selectRegion, state => ({ ...state, isSelectedRegionLoading: true })),
  on(fromActions.selectRegionComplete, (state, { region }) => ({
    ...state,
    isSelectedRegionLoading: false,
    selectedRegion: region,
  })),
  on(fromActions.selectRegionError, state => ({ ...state, isSelectedRegionLoading: false })),
);
