import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { logsActions } from '@appState';
import { LogsQueryParams } from '@models/index';
import { Store } from '@ngrx/store';
import {
  CuiButtonsModule,
  CuiCardModule,
  CuiDatepickerComponent,
  CuiTableModule,
  formatToISODate,
  formatToISODateEndOfDay,
} from 'cui-components';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CuiTableModule,
    RouterModule,
    CuiCardModule,
    FormsModule,
    ReactiveFormsModule,
    CuiDatepickerComponent,
    CuiButtonsModule,
  ],
  template: `
    <cui-card>
      <cui-card-header [showCloseBtn]="true" (close)="onCancel()">
        <h2>Notifications Logs Filter</h2>
      </cui-card-header>
      <cui-card-body>
        <form [formGroup]="form">
          <div class="date-fields">
            <cui-date-picker
              [label]="'Start Date'"
              formControlName="createdFrom"
              [required]="true"
              [maxDate]="createdToControl?.value"
            ></cui-date-picker>
            <span class="flex-spacer"></span>
            <cui-date-picker
              [label]="'End Date'"
              formControlName="createdTo"
              [required]="true"
              [maxDate]="todaysDate"
              [minDate]="createdFromControl?.value"
            ></cui-date-picker>
          </div>
        </form>
      </cui-card-body>
      <cui-card-footer>
        <cui-button (clicked)="onCancel()">Cancel</cui-button>
        <cui-button-cta [disabled]="!form.valid" (clicked)="onConfirm()">Submit</cui-button-cta>
      </cui-card-footer>
    </cui-card>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationLogsFiltersComponent implements OnInit {
  form!: FormGroup;
  todaysDate = new Date();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LogsQueryParams,
    private store: Store,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<NotificationLogsFiltersComponent>,
  ) {}

  ngOnInit(): void {
    const filterSettings = localStorage.getItem('logFilter');
    if (filterSettings) {
      const parsedSettings = JSON.parse(filterSettings);
      this.form = this.fb.group({
        createdFrom: [parsedSettings.createdFrom, [Validators.required]],
        createdTo: [parsedSettings.createdTo, [Validators.required]],
      });
    }
  }

  get createdFromControl(): AbstractControl | null {
    return this.form.get('createdFrom');
  }

  get createdToControl(): AbstractControl | null {
    return this.form.get('createdTo');
  }

  onConfirm(): void {
    if (this.form.valid) {
      const logsQuery: LogsQueryParams = {
        ...this.form.value,
        createdFrom: formatToISODate(this.form.value.createdFrom),
        createdTo: formatToISODateEndOfDay(new Date(this.form.value.createdTo)),
      };
      this.store.dispatch(logsActions.submitFilterModal({ logsQuery }));
      this.dialogRef.close(logsQuery);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
