import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.partners);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectPartners = createSelector(selectModuleState, state => state.partners);
export const selectToggle = createSelector(selectModuleState, state => state.toggle);

export const selectCurrentPartner = createSelector(selectModuleState, state => state.selectedPartner);
export const selectCurrentPartnerId = createSelector(selectModuleState, state => state.selectedPartner?.id || null);
export const selectCurrentPartnerLoading = createSelector(selectModuleState, state => state.isSelectPartnerLoading);

export const selectPartnerBusinessDataLoading = createSelector(selectModuleState, state => state.isBusinessDataLoading);
export const selectPartnerBusinessData = createSelector(selectModuleState, state => state.partnerBusinessData);

export const selectPartnerRolesLoading = createSelector(selectModuleState, state => state.isRolesLoading);
export const selectPartnerRoles = createSelector(selectModuleState, state => state.partnerRoles);

export const selectPartnerAdminUsersLoading = createSelector(selectModuleState, state => state.isPartnerAdminUsersLoading);
export const selectPartnerAdminUsers = createSelector(selectModuleState, state => state.partnerAdminUsers);

export const selectIndirectProviders = createSelector(selectModuleState, state => state.indirectProviders);
export const selectIsIndirectProvidersLoading = createSelector(selectModuleState, state => state.isIndirectProvidersLoading);
export const selectSelectedIndirectProvider = createSelector(selectModuleState, state => state.selectedIndirectProvider);
export const selectSelectedIndirectProviderId = createSelector(
  selectModuleState,
  state => state.selectedIndirectProvider && state.selectedIndirectProvider.cspIndirectProviderTenantId,
);
export const selectIsSelectedIndirectProviderLoading = createSelector(selectModuleState, state => state.isSelectedIndirectProviderLoading);
