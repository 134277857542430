import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateCspTenantRequest, CspTenant, CspTenantResponse, UpdateCspTenantRequest } from '@models/csp/tenants/tenant.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CspTenantsService {
  private readonly url = `${environment.bffUrl}/csp/tenants`;
  constructor(private httpClient: HttpClient) {}

  getAll(): Observable<CspTenant[]> {
    return this.httpClient.get<CspTenantResponse[]>(this.url);
  }

  get(id: string): Observable<CspTenant> {
    return this.httpClient.get<CspTenantResponse>(`${this.url}/${id}`);
  }

  add(req: CreateCspTenantRequest): Observable<CspTenant> {
    return this.httpClient.post<CspTenantResponse>(this.url, req);
  }

  update(id: string, req: UpdateCspTenantRequest): Observable<CspTenant> {
    return this.httpClient.patch<CspTenantResponse>(`${this.url}/${id}`, req);
  }

  delete(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.url}/${id}`);
  }

  renewSecret(id: string): Observable<CspTenantResponse> {
    return this.httpClient.post<CspTenantResponse>(`${this.url}/${id}/secret`, undefined);
  }
}
