import { HttpErrorResponse } from '@angular/common/http';
import { ActionType } from '@models/index';
import { createAction, props } from '@ngrx/store';
import { State } from './action-types.reducer';

const NAMESPACE = '[ActionTypes]';

export const getAll = createAction(`${NAMESPACE} GetAll`);
export const getAllComplete = createAction(`${NAMESPACE} GetAllComplete`, props<{ actionTypes: ActionType[] }>());
export const getAllError = createAction(`${NAMESPACE} GetAllError`, props<{ err: HttpErrorResponse }>());

export const addActionType = createAction(`${NAMESPACE} AddActionType`, props<{ actionType: ActionType }>());
export const addActionTypeComplete = createAction(`${NAMESPACE} AddActionTypeComplete`, props<{ actionType: ActionType }>());
export const addActionTypeError = createAction(`${NAMESPACE} AddActionTypeError`, props<{ err: HttpErrorResponse }>());

export const updateActionType = createAction(`${NAMESPACE} UpdateActionType`, props<{ code: string; actionType: ActionType }>());
export const updateActionTypeComplete = createAction(
  `${NAMESPACE} UpdateActionTypeComplete`,
  props<{ code: string; actionType: ActionType }>(),
);
export const updateActionTypeError = createAction(`${NAMESPACE} UpdateActionTypeError`, props<{ err: HttpErrorResponse }>());

export const deleteSelectedActionType = createAction(`${NAMESPACE} DeleteSelectedActionType`);
export const deleteSelectedActionTypeComplete = createAction(`${NAMESPACE} DeleteSelectedActionTypeComplete`);
export const deleteSelectedActionTypeError = createAction(
  `${NAMESPACE} DeleteSelectedActionTypeError`,
  props<{ err: HttpErrorResponse }>(),
);

export const selectActionType = createAction(`${NAMESPACE} SelectActionType`, props<{ code: string }>());
export const selectActionTypeComplete = createAction(`${NAMESPACE} SelectActionTypeComplete`, props<{ actionType: ActionType }>());
export const selectActionTypeError = createAction(`${NAMESPACE} SelectActionTypeError`, props<{ err: HttpErrorResponse }>());

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
