import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';

const selectModuleState = createSelector(getRootState, rootState => rootState.actionTypes);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectIsActionTypeCreateLoading = createSelector(selectModuleState, state => state.actionTypeCreateLoading);
export const selectActionTypes = createSelector(selectModuleState, state => state.actionTypes);

export const selectSelectedActionType = createSelector(selectModuleState, state => state.selectedActionType);
export const selectSelectedActionTypeId = createSelector(
  selectModuleState,
  state => state.selectedActionType && state.selectedActionType.code,
);
export const selectSelectedActionTypeLoading = createSelector(selectModuleState, state => state.selectedActionTypeLoading);
