import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isNotNullOrUndefined } from 'cui-components';
import { catchError, filter, map, of, switchMap, take, tap } from 'rxjs';
import { CommentsFiltersComponent } from 'src/app/app-pages/marketing/comments/comments-filters.component';
import { NewsArticlesService } from '../../news-articles/news-articles.service';
import * as fromActions from './comments.actions';
@Injectable()
export class CommentsEffects {
  onGetComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllComments),
      switchMap(({ commentsQuery, resetSelectedComment }) =>
        this.newsArticlesService.getAllComments(commentsQuery).pipe(
          map(comments => fromActions.getAllCommentsComplete({ comments, resetSelectedComment })),
          catchError(err => of(fromActions.getAllCommentsError({ err }))),
        ),
      ),
    ),
  );

  onSelectComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectComment),
      switchMap(({ commentId, articleId }) =>
        this.newsArticlesService.getOneComment(commentId, articleId).pipe(
          map(comment => fromActions.selectCommentComplete({ comment })),
          catchError(err => of(fromActions.selectCommentError({ err }))),
        ),
      ),
    ),
  );

  onDeleteSelectedComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedComment),
      switchMap(({ articleId, commentId }) =>
        this.newsArticlesService.deleteComment(articleId, commentId).pipe(
          map(() => fromActions.deleteSelectedCommentComplete({ commentId })),
          catchError(err => of(fromActions.deleteSelectedCommentError({ err }))),
        ),
      ),
    ),
  );

  onDeleteSelectedCommentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedCommentComplete),
      tap(() => this.router.navigate(['marketing', 'news-articles-comments'])),
      map(() => {
        const commentsFilter = JSON.parse(localStorage.getItem('commentsFilter')!);
        return fromActions.getAllComments({ commentsQuery: commentsFilter });
      }),
    ),
  );

  onOpenCommentsFilterModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.openFilterModal),
      switchMap(() =>
        this.dialog
          .open(CommentsFiltersComponent, {
            data: JSON.parse(localStorage.getItem('commentsFilter')!),
            panelClass: 'custom-modal-container',
          })
          .afterClosed()
          .pipe(
            take(1),
            // "undefined" = closed via backdrop, "null" = closed via "Cancel" button
            filter(isNotNullOrUndefined),
            map(({ commentsQuery, routeToList }) => {
              if (routeToList) this.router.navigate(['marketing', 'news-articles-comments']);

              return fromActions.getAllComments({ commentsQuery, resetSelectedComment: routeToList });
            }),
          ),
      ),
    ),
  );

  onSubmitCommentsFilterModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.submitFilterModal),
        map(({ commentsQuery }) => localStorage.setItem('commentsFilter', JSON.stringify(commentsQuery))),
      ),
    { dispatch: false },
  );

  onGoToCommentDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToCommentDetails),
        tap(({ articleId, commentId }) => this.router.navigate(['marketing', 'news-articles-comments', articleId, 'comments', commentId])),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToCommentsListPage),
      tap(() => this.router.navigate(['marketing', 'news-articles-comments'])),
      map(() => fromActions.resetState({ selectedComment: null })),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly newsArticlesService: NewsArticlesService,
    private dialog: MatDialog,
    private readonly router: Router,
  ) {}
}
