import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ROOT_STATE_EFFECTS, ROOT_STATE_SERVICES } from '@appState';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  CuiAppContainerComponent,
  CuiCardModule,
  CuiDatepickerComponent,
  CuiLibraryModule,
  CuiPageTitleComponent,
  CuiSideNavModule,
  CuiTopMenuModule,
} from 'cui-components';
import { environment } from 'src/environments/environment';
import { NewsArticlePreviewResolver } from './app-pages/news-articles/preview/news-article-preview.resolver';
import { AppRoutingModule } from './app-routing.module';
import { appReducers } from './app-state/app.store';
import { AuthenticatedTemplateComponent } from './components/authenticated-template/authenticated-template.component';
import { MainComponent } from './components/main/main.component';
import { GuidGuard } from './guards/guid.guard';
import {
  AppConfigService,
  AppInsightsLoggingService,
  DateHttpInterceptor,
  GlobalErrorHandler,
  HttpErrorInterceptor,
  HttpHighlanderInterceptor,
  loggingInitializer,
} from './infrastructure';

@NgModule({
  declarations: [MainComponent, AuthenticatedTemplateComponent],
  imports: [
    CuiAppContainerComponent,
    CuiLibraryModule.forRoot({ apiUrl: environment.bffUrl }),
    CuiTopMenuModule,
    CuiSideNavModule,
    CuiPageTitleComponent,
    HttpClientModule,
    MatTooltipModule,
    AppRoutingModule,
    MatSnackBarModule,
    MatSidenavModule,
    NgSelectModule,
    BrowserAnimationsModule,
    MatDialogModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CuiCardModule,
    CuiDatepickerComponent, // Required for datepicker to work in the application
    MatSelectModule,
    StoreModule.forRoot(appReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 20,
    }),
    EffectsModule.forRoot(ROOT_STATE_EFFECTS),
    BrowserModule.withServerTransition({
      appId: 'adminApp',
    }),
  ],
  providers: [
    ...ROOT_STATE_SERVICES,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' } as MatFormFieldDefaultOptions,
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DateHttpInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHighlanderInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loggingInitializer,
      deps: [AppConfigService, AppInsightsLoggingService],
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    NewsArticlePreviewResolver,
    GuidGuard,
  ],
  bootstrap: [MainComponent],
})
export class AppModule {}
